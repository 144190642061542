/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TFillRuleAction,
  ICardData,
  IHandleFillCustomField,
  IExecuteRuleReturnType,
  AccountCustomFieldValues,
} from '../../Card.i';
import {
  findCustomFieldById,
  findPhaseAndCustomFieldIndexes,
  updateCardCustomFieldArray,
} from '../cardUtils';
import {
  processPredefinedValues,
  processNumericValues,
} from './processActionResultValue';

/**
 * Execute the fillRule action (except for table field/column), and directly update or return a new value
 * @param {string} fieldiD - the customField id
 * @param {TFillRuleAction} action - the fillRule action details
 * @param {ICardData[]} cardFields - the array of cardFields
 * @param {AccountCustomFieldValues[]} accountFields - the account customField values
 * @param {boolean} returnNewArrayOfCardFields - if true will only return a new array of cardFields values
 * @param {IHandleFillCustomField | undefined} handleFillCustomField - function that update cardFields state
 * @returns Return the new value  of update the state directly through the handleFillCustomField
 */
export function executeRuleAction(
  fieldId: string,
  action: TFillRuleAction,
  cardFields: ICardData[],
  accountFields: AccountCustomFieldValues[],
  returnNewArrayOfCardFields: boolean,
  handleFillCustomField?: IHandleFillCustomField,
): IExecuteRuleReturnType | void {
  const { type, boolToAssign, predefinedValuesToAssign, arithmeticExpression } =
    action;
  const customField = findCustomFieldById(cardFields, fieldId);
  const { phaseIndex, customFieldIndex } = findPhaseAndCustomFieldIndexes(
    fieldId,
    cardFields,
  );
  let newValue: string | undefined = customField?.value;
  let newValueJSON: string[] | undefined = customField?.valueJSON || undefined;
  let hideField = false;
  let blockField = false;
  let hasValueChanged = false;
  let predefinedActiveOptions: string[] =
    predefinedValuesToAssign && customField?.predefinedValues
      ? predefinedValuesToAssign.filter(
          value =>
            customField &&
            customField?.predefinedValues &&
            customField.predefinedValues.includes(value),
        )
      : [];

  switch (type) {
    case 'HIDE_FIELD':
      hideField = true;
      hasValueChanged = hideField !== customField?.isHidden;
      break;
    case 'BLOCK_FIELD':
      blockField = true;
      hasValueChanged = blockField !== customField?.isDisabled;
      break;
    case 'ASSIGN_BOOL':
      newValue = `${boolToAssign}`;
      hasValueChanged = customField?.value !== newValue;
      break;
    case 'ASSIGN_AND_HIDE_BOOL':
      newValue = `${boolToAssign}`;
      hideField = true;
      hasValueChanged =
        customField?.value !== newValue || hideField !== customField?.isHidden;
      break;
    case 'ASSIGN_AND_BLOCK_BOOL':
      newValue = `${boolToAssign}`;
      blockField = true;
      hasValueChanged =
        customField?.value !== newValue ||
        blockField !== customField?.isDisabled;
      break;
    case 'ASSIGN_PREDEFINED': {
      const result = processPredefinedValues(
        'ASSIGN_PREDEFINED',
        predefinedActiveOptions,
        customField || undefined,
      );
      newValueJSON = result.newValueJSON;
      predefinedActiveOptions = customField?.predefinedValues || [];
      hasValueChanged = result.hasValueChanged;

      break;
    }
    case 'RESTRICT_PREDEFINED': {
      const result = processPredefinedValues(
        'RESTRICT_PREDEFINED',
        predefinedActiveOptions,
        customField || undefined,
      );
      predefinedActiveOptions = result.predefinedActiveOptions;
      newValueJSON = result.newValueJSON;
      hasValueChanged = result.hasValueChanged;

      break;
    }
    case 'ASSIGN_AND_HIDE_PREDEFINED': {
      const result = processPredefinedValues(
        'ASSIGN_AND_HIDE_PREDEFINED',
        predefinedActiveOptions,
        customField || undefined,
      );
      hideField = result.hideField;
      newValueJSON = result.newValueJSON;
      predefinedActiveOptions = customField?.predefinedValues || [];
      hasValueChanged = result.hasValueChanged;

      break;
    }
    case 'ASSIGN_AND_BLOCK_PREDEFINED': {
      const result = processPredefinedValues(
        'ASSIGN_AND_BLOCK_PREDEFINED',
        predefinedActiveOptions,
        customField || undefined,
      );
      blockField = result.blockField;
      newValueJSON = result.newValueJSON;
      predefinedActiveOptions = customField?.predefinedValues || [];
      hasValueChanged = result.hasValueChanged;

      break;
    }
    case 'ASSIGN_NUMERIC': {
      const result = processNumericValues(
        'ASSIGN_NUMERIC',
        cardFields,
        accountFields,
        fieldId,
        arithmeticExpression || '',
        customField || undefined,
      );
      newValue = result.newValue;
      hasValueChanged = result.hasValueChanged;

      break;
    }
    case 'ASSIGN_AND_HIDE_NUMERIC': {
      const result = processNumericValues(
        'ASSIGN_AND_HIDE_NUMERIC',
        cardFields,
        accountFields,
        fieldId,
        arithmeticExpression || '',
        customField || undefined,
      );
      newValue = result.newValue;
      hideField = result.hideField;
      hasValueChanged = result.hasValueChanged;

      break;
    }
    case 'ASSIGN_AND_BLOCK_NUMERIC': {
      const result = processNumericValues(
        'ASSIGN_AND_BLOCK_NUMERIC',
        cardFields,
        accountFields,
        fieldId,
        arithmeticExpression || '',
        customField || undefined,
      );
      newValue = result.newValue;
      blockField = result.blockField;
      hasValueChanged = result.hasValueChanged;

      break;
    }
    default:
      break;
  }

  if (returnNewArrayOfCardFields) {
    const updatedFields = returnNewArrayOfCardFields
      ? updateCardCustomFieldArray(
          cardFields,
          phaseIndex,
          customFieldIndex,
          hideField,
          blockField,
          newValue || undefined,
          (newValueJSON as string[]) || undefined,
          predefinedActiveOptions,
        )
      : [];
    // cardFields - will be used by 'default' fields without conditions
    // newFieldValues - will be used by table fields  without conditions,
    // to set the table valueJSON in each column
    return {
      cardFields: updatedFields,
      newTableValues: null,
    } as IExecuteRuleReturnType;
  }

  if (
    handleFillCustomField !== undefined &&
    !returnNewArrayOfCardFields &&
    hasValueChanged
  ) {
    handleFillCustomField(
      phaseIndex,
      customFieldIndex,
      newValue || undefined,
      newValueJSON as any,
      fieldId,
      hideField,
      predefinedActiveOptions || undefined,
      blockField,
      undefined,
    );
  }

  return undefined;
}
