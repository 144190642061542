/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';

import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { mask as masker, unMask } from 'remask';
import {
  cpfCnpjPattern,
  formatCpfCnpj,
  isCNPJValid,
  isCPFValid,
} from 'src/utils/cpfCnpjUtils';
import { IUserAll } from 'src/services/userApi';
import api, { apiViaCep } from '../../../services/api';
import {
  IFormData,
  IAccount,
  AccountResponse,
  AccountCustomFieldValue,
  PendingReassignments,
} from '../Account.i';

interface HookProps {
  accountId?: string;
  closeModal: () => void;
}

interface HookReturn {
  formik: any;
  customHandleChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  customHandlePhoneChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  handleRefreshFieldsList: () => void;
  fetchCepApi: (event: any) => void;
  responsibleUsers: IUserAll[];
  setResponsibleUsers: React.Dispatch<React.SetStateAction<IUserAll[]>>;
  accountFields: any[];
  loadingCep: boolean;
  fieldValues: AccountCustomFieldValue[];
  setFieldValues: React.Dispatch<
    React.SetStateAction<AccountCustomFieldValue[]>
  >;
  account: IAccount;
  handleResponsibleUserAlertConfirm: (shouldEditResponsible: boolean) => void;
  handleCloseResponsibleUserDialog: () => void;
  isResponsibleAlertOpen: boolean;
  isResponsibleDialogOpen: boolean;
  pendingReassignments: PendingReassignments | null;
}

export function useAccount({ accountId, closeModal }: HookProps): HookReturn {
  const history = useHistory();
  const isDev = process.env.REACT_APP_DEV_MODE === 'true';
  const [account, setAccount] = useState<IAccount>();
  const [accountFields, setAccountFields] = useState<any[]>([]);
  const [loadingCep, setLoadingCep] = useState(false);
  const [fieldValues, setFieldValues] = useState<AccountCustomFieldValue[]>([]);
  const [isResponsibleAlertOpen, setIsResponsibleAlertOpen] = useState(false);
  const [isResponsibleDialogOpen, setIsResponsibleDialogOpen] = useState(false);
  const [responsibleUsers, setResponsibleUsers] = useState<IUserAll[]>([]);
  const [pendingReassignments, setPendingReassignments] =
    useState<PendingReassignments | null>(null);

  useEffect(() => {
    if (accountId)
      api
        .get(`/accounts/${accountId}`)
        .then(response => {
          const accountData = response.data as AccountResponse;
          setAccount(accountData);

          const transformedFieldValues = response.data.fieldValues.map(
            (fv: any) => {
              return {
                id: fv.field.id,
                name: fv.field.name,
                cpfCnpj: fv.cpfCnpj,
                type: fv.field.type,
                dateType: fv.field.dateType,
                isRequired: fv.field.isRequired,
                isInactive: fv.field.isInactive,
                order: fv.field.order,
                isBigString: fv.field.isBigString,
                slug: fv.field.slug,
                predefinedValues: fv.field.predefinedValues,
                value: fv.value,
                phone: fv.phone,
                valueJSON: fv.valueJSON,
                street: fv.street,
                city: fv.city,
              };
            },
          );
          setFieldValues(transformedFieldValues);
          setResponsibleUsers(accountData.responsibleUsers || []);
        })
        .catch(error => {
          const statusError = error.response?.status;
          if (statusError === 401) {
            history.push('/listaccounts');
          }
          toast.error(
            `Falha ao buscar conta. ${error.response.data?.message || ''}`,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            },
          );
        });
  }, [accountId]);

  const handleRefreshFieldsList = useCallback(() => {
    api.get('/accountFields').then(response => {
      const orderedFields = response.data.sort(
        (a: any, b: any) => a.order - b.order,
      );
      setAccountFields(orderedFields);
    });
  }, []);

  useEffect(() => {
    handleRefreshFieldsList();
  }, []);

  const initialValues: IFormData = {
    name: account?.name || '',
    cpfCnpj: account?.cpfCnpj ? masker(account.cpfCnpj, cpfCnpjPattern) : '',
    cityCodeIBGE: account?.cityCodeIBGE || '',
    district: account?.district || '',
    zipCode: account?.zipCode || '',
    phone: account?.phone || '',
    street: account?.street || '',
    numeral: account?.numeral || '',
    complement: account?.complement || '',
    state: account?.state || '',
    corporateName: account?.corporateName || '',
    city: account?.city || '',
  };

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .matches(
        /^[ 0-9a-zA-ZÀ-ú\b\-.&']+$/g,
        'Apenas letras e números são aceitos.',
      )
      .min(4, 'O nome deve conter acima de 4 caracteres.')
      .max(60, 'O nome ultrapassou o limite de caracteres permitido.')
      .required('Nome é obrigatório.'),
    corporateName: Yup.string()
      .matches(
        /^[ 0-9a-zA-ZÀ-ú\b\-.&']+$/g,
        'Apenas letras e números são aceitos.',
      )
      .min(4, 'O nome deve conter acima de 4 caracteres.')
      .max(60, 'O nome ultrapassou o limite de caracteres permitido.')
      .optional()
      .nullable(),
    cpfCnpj: Yup.string()
      .required('CPF/CNPJ é obrigatório.')
      .test('cpfCnpj', 'CPF ou CNPJ inválido', value => {
        if (isDev) return true;
        if (!value) return true;
        const cleanedValue = value.replace(/[^\d]/g, '');
        if (cleanedValue.length === 11) {
          return isCPFValid(cleanedValue);
        }
        if (cleanedValue.length === 14) {
          return isCNPJValid(cleanedValue);
        }
        return false;
      }),
    phone: Yup.string()
      .min(10, 'Digite o DDD + Telefone')
      .optional()
      .nullable(),
    zipCode: Yup.string()
      .min(8, 'Formato de CEP inválido. Ex: 89000134.')
      .max(9, 'Formato de CEP inválido. Ex: 89000134.')
      .optional()
      .nullable(),
    street: Yup.string().optional().nullable(),
    numeral: Yup.string().optional().nullable(),
    complement: Yup.string().optional().nullable(),
    city: Yup.string()
      .max(60, 'Limite máximo de 60 caracteres')
      .optional()
      .nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        const simplifiedFieldValues = fieldValues
          .map(fv => ({
            id: fv.id,
            value:
              fv.type === 'PREDEFINED_STRINGS'
                ? fv.valueJSON
                : fv.value || null,
          }))
          .filter(fv => {
            if (!accountId) {
              return (
                !accountId &&
                fv.value !== null &&
                fv.value !== '' &&
                fv.value !== undefined
              );
            }

            return accountFields.find(f => f.id === fv.id)?.isInactive !== true;
          });
        const { ...defaultAccountValues } = values;

        const formData = {
          ...defaultAccountValues,
          fieldValues: simplifiedFieldValues,
          responsibleUser_ids: responsibleUsers.map(user => user.id),
        };

        setSubmitting(true);
        if (accountId) {
          await api
            .put(`/accounts/${accountId}`, formData)
            .then(response => {
              const { message, status, pendingReassignments } = response?.data;

              if (message && status && status === 'warning') {
                toast.warning(message, {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                  autoClose: 5000,
                });
              } else {
                toast.success('Conta Editada com sucesso.', {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                });
              }

              if (
                pendingReassignments !== null &&
                (pendingReassignments.withCard?.length > 0 ||
                  pendingReassignments.withoutCard?.length > 0)
              ) {
                setPendingReassignments(pendingReassignments);
                setIsResponsibleAlertOpen(true);
              } else {
                setPendingReassignments(null);
              }
            })
            .catch(e => {
              toast.error(`Falha ao editar conta. ${e.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              });
            });
        } else {
          await api
            .post('/accounts', formData)
            .then(response => {
              const { message, status } = response?.data;

              if (message && status && status === 'warning') {
                toast.warning(message, {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                  autoClose: 5000,
                });
              } else {
                toast.success('Conta cadastrada com sucesso.', {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                });

                closeModal();
              }
            })
            .catch(e => {
              toast.error(
                `Falha ao cadastrar conta. ${e.response.data.message}`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                },
              );
            });
        }
      } catch (error: any) {
        setSubmitting(false);

        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      }
    },
  });

  const customHandleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = unMask(ev.target.value);
    const formattedValue = formatCpfCnpj(value);
    formik.setFieldValue('cpfCnpj', formattedValue);
  };

  const customHandlePhoneChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = unMask(ev.target.value);
    const patterns =
      value.length === 10 ? ['(99) 9999-9999'] : ['(99) 99999-9999'];
    formik.setFieldValue('phone', masker(value, patterns) as string);
  };

  function fetchCepApi(event: any) {
    const value = event;
    const cep = value?.replace(/[^0-9]/g, '');

    if (cep?.length !== 8) {
      toast.warning('Formato do CEP inválido.', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      return;
    }
    formik.setFieldValue('zipCode', cep);

    setLoadingCep(true);
    apiViaCep
      .get(`${cep}/json/`)
      .then(
        (response: {
          data: {
            ibge: any;
            bairro: any;
            logradouro: any;
            complemento: any;
            uf: any;
            localidade: any;
          };
        }) => {
          if (!('erro' in response.data)) {
            formik.setFieldValue('cityCodeIBGE', response.data?.ibge);
            formik.setFieldValue('district', response.data?.bairro);
            formik.setFieldValue('street', response.data?.logradouro);
            formik.setFieldValue(
              'complement',
              response.data?.complemento || '',
            );
            formik.setFieldValue('state', response.data?.uf);
            formik.setFieldValue('city', response.data?.localidade);
            setLoadingCep(false);
          } else {
            setLoadingCep(false);
            toast.warning('CEP não encontrado.', {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
            });
          }
        },
      )
      .catch(() => {
        setLoadingCep(false);
        toast.error('Falha ao consultar CEP.', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      });
    setLoadingCep(true);
  }

  const handleResponsibleUserAlertConfirm = (
    shouldEditResponsible: boolean,
  ) => {
    setIsResponsibleAlertOpen(false);
    if (shouldEditResponsible) {
      setIsResponsibleDialogOpen(true);
    }
  };

  const handleCloseResponsibleUserDialog = () => {
    setIsResponsibleDialogOpen(false);
  };

  return {
    formik,
    customHandleChange,
    customHandlePhoneChange,
    handleRefreshFieldsList,
    fetchCepApi,
    responsibleUsers,
    setResponsibleUsers,
    accountFields,
    loadingCep,
    fieldValues,
    setFieldValues,
    account,
    isResponsibleAlertOpen,
    handleResponsibleUserAlertConfirm,
    isResponsibleDialogOpen,
    handleCloseResponsibleUserDialog,
    pendingReassignments,
  } as HookReturn;
}
