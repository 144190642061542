/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  formatSlug,
  generateSlugWithPhase,
  typeFields,
} from 'src/utils/customFieldUtils';
import { InputNumberMask } from 'src/components/InputNumberMask';
import { Autocomplete } from '@material-ui/lab';
import { styles } from '../styles';
import { IFormData } from '../CustomField.i';

interface DefaultFieldsProps {
  customField_id?: string;
  phase?: any;
  formik: any;
  setTypeCustomFieldSelected: (value: string) => void;
  initialValues: IFormData;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const DefaultFields: React.FC<DefaultFieldsProps> = ({
  customField_id,
  phase,
  formik,
  setTypeCustomFieldSelected,
  initialValues,
}) => {
  return (
    <>
      <TextField
        id="name"
        style={{
          width: customField_id ? '80%' : undefined,
          maxWidth: '550px',
        }}
        label="Nome"
        name="name"
        autoComplete="name"
        margin="normal"
        autoFocus
        fullWidth
        onChange={formik.handleChange}
        onBlur={event => {
          const formattedValue = generateSlugWithPhase(
            event.target.value,
            phase?.name || '',
          );
          if (event.target.value && !formik.values.slug)
            formik.setFieldValue('slug', formattedValue);
        }}
        value={formik.values.name}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <TextField
        id="slug"
        style={{
          width: customField_id ? '80%' : undefined,
          maxWidth: '550px',
        }}
        label="Apelido"
        name="slug"
        margin="normal"
        fullWidth
        onChange={event => {
          const formattedValue = formatSlug(event.target.value);
          formik.setFieldValue('slug', formattedValue);
        }}
        onKeyDown={event => {
          if (event.key === ' ' || !/[a-zA-Z0-9_.-]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        value={formik.values.slug}
        error={formik.touched.slug && Boolean(formik.errors.slug)}
        helperText={formik.touched.slug && formik.errors.slug}
      />
      <TextField
        id="weight"
        style={{
          width: customField_id ? '80%' : undefined,
          maxWidth: '550px',
        }}
        label="Peso"
        name="weight"
        margin="normal"
        onChange={formik.handleChange}
        value={formik.values.weight}
        InputProps={{
          inputComponent: InputNumberMask as any,
        }}
        fullWidth
      />

      <Autocomplete
        id="typeField"
        style={{
          width: customField_id ? '80%' : undefined,
          maxWidth: '550px',
        }}
        disableClearable
        getOptionLabel={typeField => typeField.label}
        getOptionSelected={(typeField, value) => typeField.id === value.id}
        options={typeFields}
        disabled={!!customField_id}
        fullWidth
        defaultValue={typeFields[0]}
        value={formik.values.typeField}
        onChange={(e, value) => {
          setTypeCustomFieldSelected(value ? value.id : '');

          formik.setFieldValue(
            'typeField',
            value !== null ? value : initialValues.typeField,
          );
          formik.setFieldValue('integrationRule', null);
        }}
        loadingText="Carregando"
        renderInput={rest => (
          <TextField
            {...rest}
            id="typeField"
            label="Tipos de Campo"
            margin="normal"
            error={formik.touched.typeField && Boolean(formik.errors.typeField)}
            helperText={formik.touched.typeField && formik.errors.typeField}
            InputProps={{
              ...rest.InputProps,
            }}
          />
        )}
      />
    </>
  );
};
