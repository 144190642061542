export const FieldTypes = Object.freeze({
  STRING: 'STRING',
  NUMERIC: 'NUMERIC',
  BOOL: 'BOOL',
  TABLE: 'TABLE',
  PREDEFINED_STRINGS: 'PREDEFINED_STRINGS',
  INTEGRATED: 'INTEGRATED',
  DOCUMENT: 'DOCUMENT',
  FILE: 'FILE',
  TIMESTAMP: 'TIMESTAMP',
});
export type FieldType = (typeof FieldTypes)[keyof typeof FieldTypes];

export const CardFieldTypes = FieldTypes;
export const CardFieldValueTypes = [
  'STRING',
  'NUMERIC',
  'BOOL',
  'TIMESTAMP',
  'DOCUMENT',
];
export const CardFieldValueJSONTypes = [
  'TABLE',
  'PREDEFINED_STRINGS',
  'INTEGRATED',
  'FILE',
];

export const allowedFileMimeTypes = [
  'application/msword',
  'application/vnd.openxmlformatsofficedocument.wordprocessingml.document',
  'application/pdf',
  'application/x-zip-compressed',
  'application/zip',
  'application/ogg',
  'application/xml',
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/svg+xml',
  'text/csv',
  'text/plain',
  'text/xml',
  'audio/mp4',
  'audio/mpeg',
  'audio/x-wav',
];

export const AccountFieldTypes = Object.freeze({
  STRING: 'STRING',
  NUMERIC: 'NUMERIC',
  BOOL: 'BOOL',
  PREDEFINED_STRINGS: 'PREDEFINED_STRINGS',
  DOCUMENT: 'DOCUMENT',
  TIMESTAMP: 'TIMESTAMP',
});

export const CFDateTypes = Object.freeze({
  DATE: 'DATE',
  TIME: 'TIME',
  DATETIME: 'DATETIME',
});

export const extendableTypes = Object.freeze({
  [FieldTypes.TIMESTAMP]: FieldTypes.TIMESTAMP,
});

export const FieldTypesWithValueJSON = Object.freeze({
  TABLE: 'TABLE',
  PREDEFINED_STRINGS: 'PREDEFINED_STRINGS',
  INTEGRATED: 'INTEGRATED',
  FILE: 'FILE',
});

export const TaskFieldTypes = Object.freeze({
  STRING: 'STRING',
  NUMERIC: 'NUMERIC',
  BOOL: 'BOOL',
  PREDEFINED_STRINGS: 'PREDEFINED_STRINGS',
  DOCUMENT: 'DOCUMENT',
  TIMESTAMP: 'TIMESTAMP',
});

export const TestTypes = Object.freeze({
  STRING: 'STRING',
  NUMERIC: 'NUMERIC',
  BOOL: 'BOOL',
  TABLE: 'TABLE',
  PREDEFINED_STRINGS: 'PREDEFINED_STRINGS',
  INTEGRATED: 'INTEGRATED',
  DOCUMENT: 'DOCUMENT',
  FILE: 'FILE',
  TIMESTAMP: 'TIMESTAMP',
});
