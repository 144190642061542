/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { dateTypes } from 'src/utils/customFieldUtils';
import { Autocomplete } from '@material-ui/lab';
import { styles } from '../styles';
import { IFormData } from '../CustomField.i';

interface TimestampProps {
  customField_id?: string;
  formik: any;
  initialValues: IFormData;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const TimestampOptions: React.FC<TimestampProps> = ({
  customField_id,
  formik,
  initialValues,
}) => {
  return (
    <Autocomplete
      id="dateType"
      style={{
        width: customField_id ? '80%' : undefined,
        maxWidth: '550px',
      }}
      disableClearable
      getOptionLabel={opt => opt.label}
      getOptionSelected={(opt, value) => opt.id === value.id}
      options={dateTypes}
      disabled={!!customField_id}
      fullWidth
      value={
        dateTypes[dateTypes.findIndex(dt => dt.id === formik.values.dateType)]
      }
      onChange={(e, value) => {
        formik.setFieldValue(
          'dateType',
          value !== null ? value.id : initialValues.dateType,
        );
      }}
      loadingText="Carregando"
      renderInput={rest => (
        <TextField
          {...rest}
          id="dateType"
          label="Tipos de tempo"
          margin="normal"
          error={formik.touched.dateType && Boolean(formik.errors.dateType)}
          helperText={formik.touched.dateType && formik.errors.dateType}
          InputProps={{
            ...rest.InputProps,
          }}
        />
      )}
    />
  );
};
