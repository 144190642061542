/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { IFormData } from '../CustomField.i';

interface PredefinedOptionsProps {
  customField_id?: string;
  formik: any;
  predefinedOptions: string[];
  setPredefinedOptions: (value: string[]) => void;
  newPredefinedOption: string;
  setNewPredefinedOption: (value: string) => void;
  initialValues: IFormData;
}

export const PredefinedOptions: React.FC<PredefinedOptionsProps> = ({
  customField_id,
  formik,
  predefinedOptions,
  setPredefinedOptions,
  newPredefinedOption,
  setNewPredefinedOption,
  initialValues,
}) => {
  return (
    <Autocomplete
      style={{
        width: customField_id ? '80%' : undefined,
        maxWidth: '550px',
      }}
      id="predifined_strings"
      multiple
      freeSolo
      limitTags={6}
      options={predefinedOptions}
      getOptionSelected={(predefinedOptions, value) =>
        predefinedOptions === value
      }
      fullWidth
      onChange={(e, value) => {
        setPredefinedOptions(value);
        setNewPredefinedOption('');
        formik.setFieldValue(
          'predefinedValues',
          value !== null ? value : initialValues.predefinedValues,
        );
      }}
      value={predefinedOptions}
      inputValue={newPredefinedOption}
      onInputChange={(e, value, reason) => {
        if (reason === 'input') {
          setNewPredefinedOption(value);
        }
      }}
      loadingText="Carregando"
      renderInput={rest => (
        <TextField
          {...rest}
          id="predifined_strings"
          label="Valores Predefinidos"
          margin="normal"
          type="text"
          placeholder="Inserir um novo valor..."
          error={
            formik.touched.predefinedValues &&
            Boolean(formik.errors.predefinedValues)
          }
          helperText={
            formik.touched.predefinedValues && formik.errors.predefinedValues
          }
          InputProps={{
            ...rest.InputProps,
          }}
        />
      )}
    />
  );
};
