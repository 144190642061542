/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { FillRuleList } from 'src/components/FillRule/FillRuleList';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { ICustomField, IPhase } from '../CustomField.i';

interface FielFillRulesProps {
  fetchCustomFieldFillRules: () => Promise<void>;
  handleSubmitWrapper: () => Promise<void>;
  isFillRulesOpen: boolean;
  setIsFillRuleOpens: (value: boolean) => void;
  customField: ICustomField;
  areaPhases: IPhase[];
}

export const FieldFillRules: React.FC<FielFillRulesProps> = ({
  fetchCustomFieldFillRules,
  handleSubmitWrapper,
  isFillRulesOpen,
  setIsFillRuleOpens,
  customField,
  areaPhases,
}) => {
  return (
    <>
      <Box
        style={{
          width: customField?.id ? '80%' : undefined,
          maxWidth: '550px',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '10px',
          marginBottom: '10px',
          marginRight: '30px',
          justifyContent: 'space-between',
        }}
        onClick={() => setIsFillRuleOpens(!isFillRulesOpen)}
      >
        <Typography>Regras de preenchimento</Typography>
        {isFillRulesOpen ? <ArrowDropUp /> : <ArrowDropDown />}
      </Box>
      <Divider
        style={{
          width: customField?.id ? '80%' : undefined,
          maxWidth: '550px',
          marginBottom: '10px',
        }}
      />
      {isFillRulesOpen && customField?.id && (
        <>
          <FillRuleList
            handleRefreshCf={fetchCustomFieldFillRules}
            customField={{
              id: customField.id,
              name: customField.name,
              type: customField.type.id,
              predefined_values: customField?.predefinedValues,
              areaId: customField.area.id,
              phase: {
                id: customField.phase.id,
                order: customField.phase.order,
              },
              fillRules: customField.fillRules,
            }}
            phases={areaPhases}
            handleSubmitWrapper={handleSubmitWrapper}
          />
        </>
      )}
    </>
  );
};
