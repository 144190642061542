import React from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  AddCircleRounded,
} from '@material-ui/icons';
import { deepClone } from 'src/utils/deepCloneObject';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { Button } from 'src/components/ui/button';
import { MoreHorizontalIcon } from 'lucide-react';
import { ISigner, SignatureTableUIProps } from '../../Signature.i';
import { useSignatureTable } from '../../hooks/useSignatureTable';
import { SignerModal } from '../../SignerModal';

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(4n+1), &:nth-of-type(4n+1) + *': {
      backgroundColor: '#f4f4f4',
    },
  },
}))(TableRow);

export const SignatureTableUI: React.FC<SignatureTableUIProps> = ({
  signers,
  handleFetchSignatures,
  hasSignatureEnded,
  signature_id,
  card_id,
  signedDocumentId,
  expiryDate,
  collectionStarted,
  resendEmail,
  resendSignedDocument,
}) => {
  const {
    signerLogOpen,
    signerModalOpen,
    currentSigner,
    page,
    rowsPerPage,
    setSignerModalOpen,
    setCurrentSigner,
    handleChangePage,
    handleOpenLog,
    handleCloseNewUserModal,
    handleDeleteSigner,
    canResendSignedDocument,
  } = useSignatureTable({
    signers,
    handleFetchSignatures,
    signature_id,
  });

  const canResendEmail =
    collectionStarted &&
    !canResendSignedDocument(hasSignatureEnded, collectionStarted);
  const canResendDocument =
    hasSignatureEnded &&
    canResendSignedDocument(hasSignatureEnded, collectionStarted);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="nowrap"
      mb={1}
    >
      <TableContainer component={Paper} elevation={2}>
        <Table size="small" aria-label="lista de Signatários">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                padding="none"
                style={{ width: '25px', paddingRight: '16px' }}
              />
              <TableCell align="left">Nome</TableCell>
              <TableCell align="left">E-mail</TableCell>
              <TableCell align="left">CPF</TableCell>
              <TableCell align="center">Data de Nascimento</TableCell>
              <TableCell align="left">Tipo</TableCell>
              <TableCell align="center">Assinatura</TableCell>
              <TableCell align="center" padding="none">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {signers.length > 0 &&
              (rowsPerPage > 0
                ? signers.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  )
                : signers
              ).map((signer: ISigner) => (
                <React.Fragment key={signer.cpf}>
                  <StyledTableRow>
                    <TableCell size="small" style={{ maxWidth: '25px' }}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenLog(signer?.id as string)}
                      >
                        {signerLogOpen[
                          signerLogOpen.findIndex(log => log.id === signer.id)
                        ]?.open ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell align="left">{signer.name}</TableCell>
                    <TableCell align="left">{signer.email}</TableCell>
                    <TableCell align="left">{signer.cpf}</TableCell>
                    <TableCell align="center">{signer.birthDate}</TableCell>
                    <TableCell align="left">{signer.type}</TableCell>
                    <TableCell align="center">
                      {signer.hasSigned ? 'Concluída' : 'Pendente'}
                    </TableCell>
                    <TableCell align="center">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            variant="ghost"
                            className="h-6 w-6 p-0"
                            name={`actions-${signer.id}`}
                            disabled={signer.hasSigned && !canResendDocument}
                          >
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontalIcon className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>Ações</DropdownMenuLabel>
                          <DropdownMenuSeparator />
                          {!signer.hasSigned && canResendEmail && (
                            <DropdownMenuItem
                              className="hover:bg-gray-200 cursor-pointer"
                              onClick={() => {
                                resendEmail(signer?.id ?? '');
                              }}
                            >
                              Reenviar Email
                            </DropdownMenuItem>
                          )}
                          {canResendDocument && (
                            <DropdownMenuItem
                              className="hover:bg-gray-200 cursor-pointer"
                              onClick={() => {
                                resendSignedDocument(signer.id ?? '');
                              }}
                            >
                              Reenviar Documento Assinado
                            </DropdownMenuItem>
                          )}

                          {!collectionStarted && (
                            <DropdownMenuItem
                              className="hover:bg-gray-200 cursor-pointer"
                              onClick={() => {
                                // setSelectedSigner(signer);
                                setCurrentSigner(signer);
                                setSignerModalOpen(true);
                              }}
                              disabled={collectionStarted}
                            >
                              Editar
                            </DropdownMenuItem>
                          )}

                          {!collectionStarted && (
                            <DropdownMenuItem
                              className="hover:bg-gray-200 cursor-pointer"
                              onClick={() => {
                                handleDeleteSigner(signer.id ?? '');
                              }}
                            >
                              Remover
                            </DropdownMenuItem>
                          )}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={12}
                    >
                      <Collapse
                        in={
                          signerLogOpen[
                            signerLogOpen.findIndex(log => log.id === signer.id)
                          ]?.open
                        }
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Typography variant="h6" gutterBottom component="div">
                            Histórico
                          </Typography>
                          <Table size="small" aria-label="Histórico assinatura">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">
                                  Data / Hora
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ width: '80%' }}
                                >
                                  Descrição
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {signer?.log &&
                                signer.log.length > 0 &&
                                signer.log
                                  .sort(
                                    (a, b) =>
                                      Date.parse(b.datetime) -
                                      Date.parse(a.datetime),
                                  )
                                  .map(log => (
                                    <TableRow
                                      key={`${signer.id} + ${log.datetime}`}
                                    >
                                      <TableCell align="center">
                                        {new Date(
                                          log.datetime,
                                        ).toLocaleString()}
                                      </TableCell>
                                      <TableCell align="left">
                                        {log.action}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))}
            <TableRow>
              <TableCell align="left" colSpan={12}>
                <IconButton
                  edge="start"
                  size="small"
                  aria-label="Adicionar Signatário"
                  style={{
                    color: !collectionStarted
                      ? '#28a745'
                      : collectionStarted
                      ? '#dfdfdf'
                      : undefined,
                    marginLeft: 1,
                  }}
                  onClick={() => {
                    setCurrentSigner(undefined);
                    setSignerModalOpen(true);
                  }}
                  disabled={collectionStarted}
                >
                  <AddCircleRounded />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                colSpan={8}
                count={signers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': '' },
                  native: true,
                  style: { margin: 0 },
                }}
                labelRowsPerPage="Linhas por página"
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {signerModalOpen && (
        <SignerModal
          open={signerModalOpen}
          closeModal={handleCloseNewUserModal}
          title="Signatário"
          currentSigner={
            currentSigner !== undefined ? deepClone(currentSigner) : undefined
          }
          signers={signers}
          documentId={signedDocumentId}
          card_id={card_id}
          signature_id={signature_id}
          expiryDate={expiryDate}
          handleFetchSignatures={handleFetchSignatures}
        />
      )}
    </Box>
  );
};
