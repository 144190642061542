/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TFillRuleAction,
  ICardData,
  IHandleFillCustomField,
  IExecuteRuleReturnType,
  IValueJSON,
  ICustomField,
  AccountCustomFieldValues,
} from '../../Card.i';
import {
  findCustomFieldById,
  findPhaseAndCustomFieldIndexes,
} from '../cardUtils';
import {
  extractUuidValuesFromNumericAndTimestampFields,
  evaluateArithmeticExpression,
} from './evaluateExpressions';

/**
 * Execute the fillRule action for table fields/columns, and directly update or return a new value
 * @param {string} fieldiD - the customField id
 * @param {TFillRuleAction} action - the fillRule action details
 * @param {ICardData[]} cardFields - the array of cardFields
 * @param {AccountCustomFieldValues[]} accountFields - the account customField values
 * @param {string} belongsToTable_id - customField table id that the current field belongs to
 * @param {IHandleFillCustomField | undefined} handleFillCustomField - function that update cardFields state
 * @param {boolean | undefined} returnNewTableData - if true will only return the new value
 * @returns Return the new value  of update the state directly through the handleFillCustomField
 */
export function executeTableFieldRuleAction(
  fieldId: string,
  action: TFillRuleAction,
  cardFields: ICardData[],
  accountFields: AccountCustomFieldValues[],
  belongsToTable_id: string,
  handleFillCustomField?: IHandleFillCustomField,
  returnNewTableData?: boolean,
): IExecuteRuleReturnType | void {
  const { type, boolToAssign, predefinedValuesToAssign, arithmeticExpression } =
    action;
  const tableId = belongsToTable_id;
  const cfTable = findCustomFieldById(cardFields, tableId);
  const tableColumns = cfTable?.tableColumns
    ? cfTable.tableColumns.map(columns => ({ ...columns }))
    : [];
  let fieldColumnIndex = 0;
  const tableValueJSON: IValueJSON[] = cfTable?.valueJSON || [];
  const { phaseIndex, customFieldIndex } = findPhaseAndCustomFieldIndexes(
    tableId,
    cardFields,
  );
  let hideField = false;
  let blockField = false;
  let hasValueChanged = false;
  const predefinedActiveOptions: string[] = predefinedValuesToAssign
    ? predefinedValuesToAssign.filter(
        value =>
          tableColumns &&
          tableColumns[tableColumns.findIndex(i => i.id === fieldId)]
            .predefinedValues &&
          tableColumns[
            tableColumns.findIndex(i => i.id === fieldId)
          ]?.predefinedValues!.includes(value),
      )
    : [];
  let uuidValues: { [key: string]: string } = {};
  let numericExpressionResult: number | null = null;

  if (!cfTable && !tableValueJSON && !tableColumns) return undefined;

  fieldColumnIndex = tableColumns.findIndex(f => f.id === fieldId);

  switch (type) {
    case 'HIDE_FIELD':
      hideField = true;
      if (tableColumns[fieldColumnIndex]?.isHidden !== hideField) {
        tableColumns[fieldColumnIndex].isHidden = hideField;
        hasValueChanged = hideField;
      }
      break;
    case 'BLOCK_FIELD':
      blockField = true;
      if (tableColumns[fieldColumnIndex]?.isDisabled !== blockField) {
        tableColumns[fieldColumnIndex].isDisabled = blockField;
        hasValueChanged = true;
      }
      break;
    case 'ASSIGN_BOOL':
      tableValueJSON.forEach((row: any, index: number) => {
        if (
          boolToAssign !== undefined &&
          boolToAssign !== null &&
          boolToAssign.toString() !== tableValueJSON[index][fieldId]?.value
        ) {
          if (!tableValueJSON[index][fieldId]) {
            tableValueJSON[index][fieldId] = { value: `${boolToAssign}` };
          } else {
            tableValueJSON[index][fieldId].value = `${boolToAssign}`;
          }
          hasValueChanged = true;
        }
      });
      break;
    case 'ASSIGN_AND_HIDE_BOOL':
      hideField = true;
      if (tableColumns[fieldColumnIndex]?.isHidden !== hideField) {
        tableColumns[fieldColumnIndex].isHidden = hideField;
        hasValueChanged = hideField;
      }

      tableValueJSON.forEach((row: any, index: number) => {
        if (
          boolToAssign !== undefined &&
          boolToAssign !== null &&
          boolToAssign.toString() !== tableValueJSON[index][fieldId]?.value
        ) {
          if (!tableValueJSON[index][fieldId]) {
            tableValueJSON[index][fieldId] = { value: `${boolToAssign}` };
          } else {
            tableValueJSON[index][fieldId].value = `${boolToAssign}`;
          }
          hasValueChanged = true;
        }
      });
      break;
    case 'ASSIGN_AND_BLOCK_BOOL':
      blockField = true;
      if (tableColumns[fieldColumnIndex]?.isDisabled !== blockField) {
        tableColumns[fieldColumnIndex].isDisabled = blockField;
        hasValueChanged = true;
      }
      tableValueJSON.forEach((row: any, index: number) => {
        if (
          boolToAssign !== undefined &&
          boolToAssign !== null &&
          boolToAssign.toString() !== tableValueJSON[index][fieldId]?.value
        ) {
          if (!tableValueJSON[index][fieldId]) {
            tableValueJSON[index][fieldId] = { value: `${boolToAssign}` };
          } else {
            tableValueJSON[index][fieldId].value = `${boolToAssign}`;
          }
          hasValueChanged = true;
        }
      });
      break;
    case 'ASSIGN_PREDEFINED':
      tableValueJSON.forEach((row: any, index: number) => {
        if (!tableValueJSON[index][fieldId]) {
          tableValueJSON[index][fieldId] = { valueJSON: [] };
        }
        const currentValue: string[] =
          (tableValueJSON[index][fieldId].valueJSON as string[]) || [];

        if (currentValue !== predefinedActiveOptions) {
          tableValueJSON[index][fieldId].valueJSON = predefinedActiveOptions;
          hasValueChanged = true;
        }
      });
      break;
    case 'RESTRICT_PREDEFINED':
      if (tableColumns[fieldColumnIndex].predefinedActiveOptions) {
        tableColumns[fieldColumnIndex].predefinedActiveOptions =
          predefinedActiveOptions as string[];
      }

      if (
        tableColumns[fieldColumnIndex].predefinedActiveOptions !==
        tableColumns[fieldColumnIndex].predefinedValues
      )
        hasValueChanged = true;

      tableValueJSON.forEach((row: any, index: number) => {
        if (!tableValueJSON[index][fieldId]) {
          tableValueJSON[index][fieldId] = { valueJSON: [] };
        }
        const currentValue: string[] =
          (tableValueJSON[index][fieldId].valueJSON as string[]) || [];

        if (
          predefinedActiveOptions.length === 1 &&
          currentValue !== predefinedActiveOptions
        ) {
          tableValueJSON[index][fieldId].valueJSON = predefinedActiveOptions;
          hasValueChanged = true;
        } else if (
          predefinedActiveOptions.length > 1 &&
          currentValue.length > 0 &&
          Array.isArray(currentValue)
        ) {
          tableValueJSON[index][fieldId].valueJSON = currentValue.filter(
            (value: string) => predefinedActiveOptions.includes(value),
          );
          hasValueChanged = true;
        }
      });
      break;
    case 'ASSIGN_AND_HIDE_PREDEFINED':
      hideField = true;
      if (tableColumns[fieldColumnIndex]?.isHidden !== hideField) {
        tableColumns[fieldColumnIndex].isHidden = hideField;
        hasValueChanged = hideField;
      }
      if (tableColumns[fieldColumnIndex].predefinedActiveOptions) {
        tableColumns[fieldColumnIndex].predefinedActiveOptions =
          tableColumns[fieldColumnIndex].predefinedValues || [];
      }

      tableValueJSON.forEach((row: any, index: number) => {
        if (!tableValueJSON[index][fieldId]) {
          tableValueJSON[index][fieldId] = { valueJSON: [] };
        }
        const currentValue: string[] =
          (tableValueJSON[index][fieldId].valueJSON as string[]) || [];

        if (currentValue !== predefinedActiveOptions) {
          tableValueJSON[index][fieldId].valueJSON = predefinedActiveOptions;
          hasValueChanged = true;
        }
      });
      break;
    case 'ASSIGN_AND_BLOCK_PREDEFINED':
      blockField = true;
      if (tableColumns[fieldColumnIndex]?.isDisabled !== blockField) {
        tableColumns[fieldColumnIndex].isDisabled = blockField;
        hasValueChanged = true;
      }
      if (tableColumns[fieldColumnIndex].predefinedActiveOptions) {
        tableColumns[fieldColumnIndex].predefinedActiveOptions =
          tableColumns[fieldColumnIndex].predefinedValues || [];
      }

      tableValueJSON.forEach((row: any, index: number) => {
        if (!tableValueJSON[index][fieldId]) {
          tableValueJSON[index][fieldId] = { valueJSON: [] };
        }
        const currentValue: string[] =
          (tableValueJSON[index][fieldId].valueJSON as string[]) || [];

        if (currentValue !== predefinedActiveOptions) {
          tableValueJSON[index][fieldId].valueJSON = predefinedActiveOptions;
          hasValueChanged = true;
        }
      });
      break;
    case 'ASSIGN_NUMERIC':
      uuidValues = extractUuidValuesFromNumericAndTimestampFields(
        cardFields,
        accountFields,
        fieldId,
      );

      tableValueJSON.forEach((row: any, index: number) => {
        numericExpressionResult = evaluateArithmeticExpression(
          arithmeticExpression as string,
          uuidValues,
          cfTable as ICustomField,
          index,
        );

        if (
          numericExpressionResult !== null &&
          typeof numericExpressionResult === 'number' &&
          !Number.isNaN(numericExpressionResult)
        ) {
          // format to 2 digits only after decimal point
          const formatNumber = Math.floor(numericExpressionResult * 100) / 100;
          const numberToString = formatNumber.toString();

          if (!tableValueJSON[index][fieldId]) {
            tableValueJSON[index][fieldId] = { value: numberToString };
          } else {
            tableValueJSON[index][fieldId].value = numberToString;
          }
          hasValueChanged = true;
        }
      });
      break;
    case 'ASSIGN_AND_HIDE_NUMERIC':
      hideField = true;
      if (tableColumns[fieldColumnIndex]?.isHidden !== hideField) {
        tableColumns[fieldColumnIndex].isHidden = hideField;
        hasValueChanged = hideField;
      }
      uuidValues = extractUuidValuesFromNumericAndTimestampFields(
        cardFields,
        accountFields,
        fieldId,
      );

      tableValueJSON.forEach((row: any, index: number) => {
        numericExpressionResult = evaluateArithmeticExpression(
          arithmeticExpression as string,
          uuidValues,
          cfTable as ICustomField,
          index,
        );

        if (
          numericExpressionResult !== null &&
          typeof numericExpressionResult === 'number' &&
          !Number.isNaN(numericExpressionResult)
        ) {
          const formatNumber = Math.floor(numericExpressionResult * 100) / 100;
          const numberToString = formatNumber.toString();

          if (!tableValueJSON[index][fieldId]) {
            tableValueJSON[index][fieldId] = { value: numberToString };
          } else {
            tableValueJSON[index][fieldId].value = numberToString;
          }
          hasValueChanged = true;
        }
      });
      break;
    case 'ASSIGN_AND_BLOCK_NUMERIC':
      blockField = true;
      if (tableColumns[fieldColumnIndex]?.isDisabled !== blockField) {
        tableColumns[fieldColumnIndex].isDisabled = blockField;
        hasValueChanged = true;
      }
      uuidValues = extractUuidValuesFromNumericAndTimestampFields(
        cardFields,
        accountFields,
        fieldId,
      );

      tableValueJSON.forEach((row: any, index: number) => {
        numericExpressionResult = evaluateArithmeticExpression(
          arithmeticExpression as string,
          uuidValues,
          cfTable as ICustomField,
          index,
        );

        if (
          numericExpressionResult !== null &&
          typeof numericExpressionResult === 'number' &&
          !Number.isNaN(numericExpressionResult)
        ) {
          const formatNumber = Math.floor(numericExpressionResult * 100) / 100;
          const numberToString = formatNumber.toString();

          if (!tableValueJSON[index][fieldId]) {
            tableValueJSON[index][fieldId] = { value: numberToString };
          } else {
            tableValueJSON[index][fieldId].value = numberToString;
          }
          hasValueChanged = true;
        }
      });
      break;
    default:
      break;
  }

  if (returnNewTableData) {
    return {
      cardFields: [],
      newTableValues: {
        tableColumns,
        valueJSON: tableValueJSON,
      },
    } as IExecuteRuleReturnType;
  }

  if (handleFillCustomField && hasValueChanged) {
    handleFillCustomField(
      phaseIndex,
      customFieldIndex,
      undefined,
      tableValueJSON as any,
      tableId,
      undefined,
      undefined,
      undefined,
      tableColumns,
    );
  }

  return undefined;
}
