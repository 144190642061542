/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ICustomField,
  ICardData,
  AccountCustomFieldValues,
} from '../../Card.i';
import {
  extractUuidValuesFromNumericAndTimestampFields,
  evaluateArithmeticExpression,
} from './evaluateExpressions';

/**
 * Set new field values to predefined customField based on the fillRule action type
 * @param {string} actionType - fillRule action type
 * @param {string[]} predefinedValuesToAssign - new predefined value to asign
 * @param {ICustomField | undefined} customField - customField object
 * @returns the new field values
 */
export function processPredefinedValues(
  actionType: string,
  predefinedValuesToAssign: string[],
  customField: ICustomField | undefined,
) {
  let hideField = false;
  let blockField = false;
  let predefinedActiveOptions = customField?.predefinedValues || [];
  let newValueJSON: string[] = customField?.valueJSON || [];

  switch (actionType) {
    case 'ASSIGN_PREDEFINED':
      newValueJSON = predefinedValuesToAssign;
      break;
    case 'RESTRICT_PREDEFINED':
      predefinedActiveOptions = predefinedValuesToAssign;
      if (predefinedActiveOptions.length === 1) {
        newValueJSON = predefinedActiveOptions;
      } else if (predefinedActiveOptions.length > 1 && customField?.valueJSON) {
        newValueJSON = customField.valueJSON.filter((value: string) =>
          predefinedActiveOptions.includes(value),
        );
      }
      break;
    case 'ASSIGN_AND_HIDE_PREDEFINED':
      newValueJSON = predefinedValuesToAssign;
      hideField = true;
      break;
    case 'ASSIGN_AND_BLOCK_PREDEFINED':
      newValueJSON = predefinedValuesToAssign;
      blockField = true;
      break;
    default:
      break;
  }

  const hasValueChanged =
    (hideField && hideField !== customField?.isHidden) ||
    (blockField && blockField !== customField?.isDisabled) ||
    customField?.valueJSON !== newValueJSON ||
    predefinedActiveOptions !== customField?.predefinedValues;

  return {
    hideField,
    blockField,
    newValueJSON,
    predefinedActiveOptions,
    hasValueChanged,
  };
}

/**
 * Set new field values to numeric customField based on the fillRule action type
 * @param {string} actionType - fillRule action type
 * @param {ICardData[]} cardFields - the arary of cardFields
 * @param {AccountCustomFieldValues[]} accountFields - the account customField values
 * @param {string} fieldId - customField id
 * @param {string | undefined} arithmeticExpression - arithmetic expression defined in the fillRule action
 * @param {ICustomField | undefined} customField - customField object
 * @returns the new field values
 */
export function processNumericValues(
  actionType: string,
  cardFields: ICardData[],
  accountFields: AccountCustomFieldValues[],
  fieldId: string,
  arithmeticExpression: string | undefined,
  customField: ICustomField | undefined,
) {
  let hideField = false;
  let blockField = false;
  let hasValueChanged = false;

  let uuidValues: { [key: string]: string } = {};
  let numericExpressionResult: number | null = null;
  let newValue: string | undefined;

  if (actionType === 'ASSIGN_AND_HIDE_NUMERIC') {
    hideField = true;
  } else if (actionType === 'ASSIGN_AND_BLOCK_NUMERIC') {
    blockField = true;
  }

  uuidValues = extractUuidValuesFromNumericAndTimestampFields(
    cardFields,
    accountFields,
    fieldId,
  );
  numericExpressionResult = evaluateArithmeticExpression(
    arithmeticExpression as string,
    uuidValues,
  );

  if (
    numericExpressionResult !== null &&
    typeof numericExpressionResult === 'number' &&
    !Number.isNaN(numericExpressionResult)
  ) {
    const formatNumber = Math.floor(numericExpressionResult * 100) / 100;
    const numberToString = formatNumber.toString();
    newValue = numberToString;
    hasValueChanged =
      customField?.isHidden !== hideField ||
      customField?.isDisabled !== blockField ||
      customField?.value !== newValue;
  }

  return {
    hideField,
    blockField,
    newValue,
    hasValueChanged,
  };
}
