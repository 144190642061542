import React from 'react';
import {
  Dialog as ConfirmationDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  Button as ButtonMaterialUI,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import { styles } from '../styles';

interface ConfirmEditDialogProps {
  confirmationModalOpen: boolean;
  setUnsavedChanges: (value: boolean) => void;
  setConfirmationModalOpen: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  loading: boolean;
  nextLocation?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const ConfirmEditDialog: React.FC<ConfirmEditDialogProps> = ({
  confirmationModalOpen,
  setUnsavedChanges,
  setConfirmationModalOpen,
  formik,
  loading,
  nextLocation,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <ConfirmationDialog
      open={confirmationModalOpen}
      onClose={() => setUnsavedChanges(false)}
    >
      <DialogTitle>Mudanças não salvas</DialogTitle>
      <DialogContent>
        <Typography>
          Você tem mudanças não salvas. Deseja sair sem salvar?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setConfirmationModalOpen(false);
          }}
        >
          Ficar
        </Button>
        <ButtonMaterialUI
          className={classes.buttonSubmit}
          disabled={formik.isSubmitting || loading}
          size="large"
          variant="contained"
          color="inherit"
          onClick={() => {
            setConfirmationModalOpen(false);
            setUnsavedChanges(false);
            if (nextLocation) {
              history.push(nextLocation);
            }
          }}
        >
          Sair sem salvar
        </ButtonMaterialUI>
      </DialogActions>
    </ConfirmationDialog>
  );
};
