/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import {
  Checkbox,
  createStyles,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
  triggerDispatchTypes,
  triggerPhaseTypes,
} from 'src/utils/conditionTypes';
import { InputPercentageMask } from 'src/components/InputPercentageMask';
import { mask as masker, unMask } from 'remask';
import { cpfCnpjPattern, formatCpfCnpj } from 'src/utils/cpfCnpjUtils';
import { getDateInputType } from 'src/utils/customFieldUtils';
import { formatDatetimeLocal } from 'src/utils/dateFormatAndComparisonUtils';
import { IOptionValueProps } from '../Condition.i';
import { CustomRenderInputTags } from '../../AutoCompleteCustomComponents/CustomRenderInputTags';
import { InputNumberMask } from '../../InputNumberMask';
import { boolType } from '../../../utils/fillRuleUtils';
import { styles } from '../styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const OptionValue: React.FC<IOptionValueProps> = ({
  handleChange,
  customField,
  phases,
  groupIndex,
  itemIndex,
  fieldValue,
  itemType,
  isTriggerCondition,
}) => {
  const classes = useStyles();
  const formattedValue =
    itemType === 'accountFieldFixed' && customField?.id === 'cpfCnpj'
      ? formatCpfCnpj(fieldValue)
      : fieldValue;
  const timestampValue =
    customField?.type === 'TIMESTAMP' &&
    'dateType' in customField &&
    customField.dateType === 'DATETIME' &&
    fieldValue
      ? formatDatetimeLocal(fieldValue)
      : fieldValue;

  if (
    !isTriggerCondition &&
    itemType === 'phase' &&
    phases &&
    phases.length > 0
  ) {
    const selectedPhasesId = fieldValue || [];
    const selectedPhases =
      selectedPhasesId.length > 0
        ? phases?.filter(phase => selectedPhasesId.includes(phase.id))
        : [];

    return (
      <Autocomplete
        id="phaseValue"
        multiple
        disableCloseOnSelect
        limitTags={1}
        options={phases}
        getOptionLabel={phase => phase.name}
        getOptionSelected={(phase, value) => phase.id === value.id}
        value={selectedPhases}
        onChange={(e, value) => {
          const mapPhaseId = value ? value.map(v => v.id) : [];
          handleChange(
            'phaseValue',
            mapPhaseId.toString(),
            groupIndex,
            itemIndex,
          );
        }}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
              style={{
                alignItems: 'start',
                paddingTop: 0,
                paddingLeft: 0,
                paddingBottom: 0,
              }}
            />
            {option.name}
          </>
        )}
        renderTags={(tagValue, getTagProps) => {
          return (
            <CustomRenderInputTags
              tagValue={tagValue}
              getTagProps={getTagProps}
              size="small"
            />
          );
        }}
        loadingText="Carregando"
        renderInput={params => (
          <TextField
            {...params}
            label="Valor"
            margin="dense"
            name="phaseValue"
            variant="outlined"
            size="small"
            autoComplete="off"
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: '14px',
                width: '300px',
                display: 'flex',
                flexWrap: 'nowrap',
              },
            }}
          />
        )}
      />
    );
  }

  if (itemType === 'task') {
    return (
      <Autocomplete
        id="taskDone"
        getOptionLabel={opt => opt || ''}
        getOptionSelected={(opt, value) => opt === value || value === ''}
        options={['Concluída', 'Não concluída']}
        value={
          fieldValue === undefined
            ? ''
            : fieldValue === true || fieldValue === 'true'
            ? 'Concluída'
            : 'Não concluída'
        }
        onChange={(e, value) =>
          handleChange('taskDone', value, groupIndex, itemIndex)
        }
        disableClearable
        size="small"
        loadingText="Carregando"
        className={classes.conditionField}
        renderInput={rest => (
          <TextField
            {...rest}
            id="taskDone"
            label="Situação"
            margin="dense"
            name="taskDone"
            variant="outlined"
            size="small"
            onKeyDown={e => {
              e.key === 'Enter' && e.preventDefault();
            }}
            InputProps={{
              ...rest.InputProps,
              style: {
                fontSize: '14px',
                width: '188px',
              },
            }}
          />
        )}
      />
    );
  }

  if (isTriggerCondition) {
    if (itemType === 'dispatch' || itemType === 'phase') {
      return (
        <Autocomplete
          getOptionLabel={opt => opt.label}
          getOptionSelected={(opt, value) => opt.id === value.id}
          options={
            itemType === 'phase' ? triggerPhaseTypes : triggerDispatchTypes
          }
          onChange={(e, value) =>
            handleChange(
              itemType === 'dispatch' ? 'dispatchType' : 'phaseEntering',
              value.id,
              groupIndex,
              itemIndex,
            )
          }
          value={fieldValue || null}
          disableClearable
          size="small"
          className={classes.conditionField}
          loadingText="Carregando"
          renderInput={rest => (
            <TextField
              {...rest}
              id={itemType === 'dispatch' ? 'dispatchType' : 'phaseEntering'}
              label={itemType === 'dispatch' ? 'Ação' : 'Entrar/Sair'}
              margin="dense"
              name={itemType === 'dispatch' ? 'dispatchType' : 'phaseEntering'}
              variant="outlined"
              size="small"
              onKeyDown={e => {
                e.key === 'Enter' && e.preventDefault();
              }}
              InputProps={{
                ...rest.InputProps,
                style: {
                  fontSize: '14px',
                  width: '188px',
                },
              }}
            />
          )}
        />
      );
    }
    if (itemType === 'progress' || itemType === 'staleCard') {
      return (
        <TextField
          id={itemType}
          label={itemType === 'progress' ? 'Prcentual' : 'Horas'}
          margin="dense"
          name={itemType}
          variant="outlined"
          type={itemType === 'staleCard' ? 'number' : undefined}
          value={fieldValue}
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onChange={e => {
            handleChange(
              itemType === 'progress' ? 'progress' : 'staleCard',
              e.target.value,
              groupIndex,
              itemIndex,
            );
          }}
          size="small"
          style={{ marginRight: '3px' }}
          InputProps={{
            style: {
              fontSize: '14px',
              width: '100px',
            },
            inputProps:
              itemType === 'staleCard'
                ? {
                    min: 1,
                  }
                : undefined,
            inputComponent:
              itemType === 'progress'
                ? (InputPercentageMask as any)
                : undefined,
          }}
          autoComplete="off"
        />
      );
    }
  }

  return customField &&
    ((customField.type === 'STRING' && customField.id !== 'state') ||
      customField.type === 'NUMERIC' ||
      customField.type === 'TABLE' ||
      customField.type === 'INTEGRATED') ? (
    <TextField
      id="customFieldValue"
      label="Valor"
      margin="dense"
      name="customFieldValue"
      variant="outlined"
      size="small"
      value={
        formattedValue ||
        (!fieldValue && customField.type === 'NUMERIC' ? null : '')
      }
      autoComplete="off"
      onChange={e => {
        if (customField?.id === 'cpfCnpj') {
          const value = unMask(e.target.value);
          const formattedValue = formatCpfCnpj(value);
          handleChange(
            'customFieldValue',
            formattedValue,
            groupIndex,
            itemIndex,
          );
        } else {
          handleChange(
            'customFieldValue',
            e.target.value,
            groupIndex,
            itemIndex,
          );
        }
      }}
      onKeyDown={e => {
        e.key === 'Enter' && e.preventDefault();
      }}
      className={classes.conditionField}
      InputProps={{
        style: {
          fontSize: '14px',
          width: '188px',
        },
        inputComponent:
          customField.type === 'NUMERIC'
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (InputNumberMask as any)
            : undefined,
      }}
    />
  ) : customField &&
    customField.type === 'TIMESTAMP' &&
    'dateType' in customField ? (
    <TextField
      id="customFieldValue"
      label="Valor"
      margin="dense"
      name="customFieldValue"
      variant="outlined"
      size="small"
      type={getDateInputType(customField.dateType)}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={timestampValue ?? ''}
      autoComplete="off"
      onBlur={e =>
        handleChange('customFieldValue', e.target.value, groupIndex, itemIndex)
      }
      /* onChange={e =>
        handleChange('customFieldValue', e.target.value, groupIndex, itemIndex)
      } */
      onKeyDown={e => {
        e.key === 'Enter' && e.preventDefault();
      }}
      className={classes.conditionField}
      inputProps={{
        style: {
          fontSize: '14px',
          width: '160px',
        },
        inputformat:
          customField.dateType === 'DATE'
            ? 'dd/mm/yyyy'
            : customField.dateType === 'TIME'
            ? 'hh:mm'
            : 'yyyy-MM-ddThh:mm',
        pattern:
          customField.dateType === 'DATE'
            ? '[0-9]{4}-[0-9]{2}-[0-9]{2}'
            : customField.dateType === 'TIME'
            ? '[0-9]{2}:[0-5]{1}[0-9]{1}'
            : '[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}',
        min:
          customField.dateType === 'DATE'
            ? '1900-05-01'
            : customField.dateType === 'TIME'
            ? '00:00'
            : '1900-05-01T00:00',
        max:
          customField.dateType === 'DATE'
            ? '2999-12-31'
            : customField.dateType === 'TIME'
            ? '23:59'
            : '2999-12-31T23:59',
      }}
    />
  ) : customField &&
    // customField.id === 'state' is a fixed account field
    ((customField.type === 'STRING' && customField.id === 'state') ||
      customField.type === 'PREDEFINED_STRINGS') &&
    'predefinedValues' in customField &&
    customField?.predefinedValues !== undefined ? (
    <Autocomplete
      id="customFieldValue"
      multiple={customField.id !== 'state'}
      disableCloseOnSelect
      limitTags={1}
      options={customField.predefinedValues}
      disabled={customField.predefinedValues.length === 0}
      getOptionLabel={predefinedValues => predefinedValues}
      getOptionSelected={(predefinedValues, value) =>
        predefinedValues === value
      }
      value={
        fieldValue && customField.id !== 'state'
          ? fieldValue.split(',')
          : customField.id === 'state'
          ? fieldValue || ''
          : []
      }
      onChange={(e, value) => {
        handleChange(
          'customFieldValue',
          value.toString(),
          groupIndex,
          itemIndex,
        );
      }}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            style={{
              alignItems: 'start',
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 0,
            }}
          />
          {option}
        </>
      )}
      renderTags={(tagValue, getTagProps) => {
        return (
          <CustomRenderInputTags
            tagValue={tagValue}
            getTagProps={getTagProps}
            size="small"
          />
        );
      }}
      loadingText="Carregando"
      renderInput={params => (
        <TextField
          {...params}
          label="Valor"
          margin="dense"
          name="customFieldValue"
          variant="outlined"
          size="small"
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            style: {
              fontSize: '14px',
              width: '188px',
              display: 'flex',
              flexWrap: 'nowrap',
            },
          }}
        />
      )}
    />
  ) : customField && customField.type === 'BOOL' ? (
    <Autocomplete
      id="customFieldValue"
      disableClearable
      options={boolType}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => option.id === value.id}
      value={
        boolType[boolType.findIndex(field => field.id === fieldValue)] || null
      }
      onChange={(e, value) => {
        handleChange('customFieldValue', value.id, groupIndex, itemIndex);
      }}
      loadingText="Carregando"
      renderInput={params => (
        <TextField
          {...params}
          label="Valor"
          margin="dense"
          name="customFieldValue"
          variant="outlined"
          size="small"
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            style: {
              fontSize: '14px',
              width: '188px',
            },
          }}
        />
      )}
    />
  ) : null;
};
