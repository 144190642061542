/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Typography,
  makeStyles,
  Box,
  TextField,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GridRowParams } from '@material-ui/data-grid';
import { History } from 'history';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { Button } from 'src/components/ui/button';
import Modal from '../../../components/Modal_cards';
import { IAreaWithPhases } from '../ListAreasAndPhases';
import { IPhase } from '../../Phase/Phase.i';
import api from '../../../services/api';
import DataTable from '../../../components/SortableTable/DataGrid';
import { ICustomField } from '../../CustomField/CustomField.i';
import { ITask } from '../../Task/Task.i';
import TitleAndButton from '../../../components/TitleAndButton';
import { CustomField } from '../../CustomField';
import Task from '../../Task';
import { fieldsTableColumns, tasksTableColumns } from './tablesColumns';

interface PhaseDataProps {
  area: IAreaWithPhases;
  setAreas: (areas: IAreaWithPhases[]) => void;
  selectedPhase: string;
}

export interface TaskWithPhase extends ITask {
  phase: {
    id: string;
    name: string;
  };
}

const useStyles = makeStyles(theme => ({
  title: { fontSize: '25px', fontWeight: 'bold', paddingBottom: '5px' },
  card: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '650px',
    },
  },
  columnActions: {
    width: '40px',
  },
  phasesContainer: {
    maxWidth: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: '10px',
    gap: '10px',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
}));

const AreaPhaseData: React.FC<PhaseDataProps> = ({
  area,
  setAreas,
  selectedPhase,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [selectedPhases, setSelectedPhases] = useState<IPhase[]>([]);
  const [selectedFields, setSelectedFields] = useState<ICustomField[]>();
  const [selectedTasks, setSelectedTasks] = useState<TaskWithPhase[]>();
  const [fieldModalOpen, setFieldModalOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [phase, setPhase] = useState<string>();

  useEffect(() => {
    if (selectedPhase) {
      setSelectedPhases(
        area.phases.filter(phase => phase.id === selectedPhase),
      );
    } else {
      setSelectedPhases([]);
    }
  }, [selectedPhase]);

  useEffect(() => {
    if (selectedPhases.length > 0) {
      setSelectedFields(
        selectedPhases
          .map(phase => {
            return phase.customFields.map(field => {
              return { ...field, phase: { id: phase.id, name: phase.name } };
            });
          })
          .flat(),
      );

      setSelectedTasks(
        selectedPhases
          .map(phase => {
            return phase.cardTasks.map(task => {
              return { ...task, phase: { id: phase.id, name: phase.name } };
            });
          })
          .flat(),
      );
    } else {
      setSelectedFields(undefined);
      setSelectedTasks(undefined);
    }
  }, [selectedPhases]);

  const fields: ICustomField[] = React.useMemo(
    () =>
      area.phases
        .sort((a, b) => a.order - b.order)
        .map(phase => {
          const newPhaseFields = phase.customFields;
          newPhaseFields.sort((a, b) =>
            a.phaseOrder === -1 || b.phaseOrder === -1
              ? 1
              : a.phaseOrder - b.phaseOrder,
          );
          return newPhaseFields.map(field => {
            return { ...field, phase: { id: phase.id, name: phase.name } };
          });
        })
        .flat(),
    [area],
  );

  const tasks = React.useMemo(
    () =>
      area.phases
        .sort((a, b) => a.order - b.order)
        .map(phase => {
          const newPhaseTasks = phase.cardTasks;
          newPhaseTasks.sort((a, b) => a.order - b.order);
          return phase.cardTasks.map(task => {
            return { ...task, phase: { id: phase.id, name: phase.name } };
          });
        })
        .flat(),
    [area],
  );

  const handleRefreshArea = useCallback(() => {
    api.get('/areas').then(response => setAreas(response.data));
  }, []);

  const handleSelectPhase = useCallback(
    (phase_id: string) => {
      if (selectedPhases.map(phase => phase.id).includes(phase_id)) {
        setSelectedPhases(oldValue =>
          oldValue.filter(phase => phase.id !== phase_id),
        );
      } else {
        const phase = area.phases.find(phase => phase.id === phase_id);
        if (phase) {
          const newPhase = phase;
          newPhase.customFields.sort((a, b) =>
            a.phaseOrder === -1 || b.phaseOrder === -1
              ? 1
              : a.phaseOrder - b.phaseOrder,
          );
          newPhase.cardTasks.sort((a, b) => a.order - b.order);
          setSelectedPhases(oldValue => [...oldValue, newPhase]);
        }
      }
    },
    [selectedPhases, area.phases],
  );

  const handleCloseFieldModal = useCallback(() => {
    handleRefreshArea();
    setFieldModalOpen(false);
  }, []);

  const handleCloseTaskModal = useCallback(() => {
    handleRefreshArea();
    setTaskModalOpen(false);
  }, []);

  const handleEditTask = useCallback(
    (phase_id: string, task_id: string, history: History, areaId: string) => {
      history.push(`/phases/${phase_id}/tasks/${task_id}`, areaId);
    },
    [],
  );

  const handleEditField = useCallback(
    (
      phase_id: string,
      customField_id: string,
      history: History,
      areaId: string,
    ) => {
      history.push(
        `/phases/${phase_id}/customFields/${customField_id}`,
        areaId,
      );
    },
    [],
  );

  const onFieldRowClick = useCallback((params: GridRowParams) => {
    handleEditField(
      params?.row.phase.id,
      params?.id?.toString(),
      history as never,
      area.id,
    );
  }, []);

  const onTaskRowClick = useCallback((params: GridRowParams) => {
    handleEditTask(
      params.row.phase.id,
      params?.id?.toString(),
      history as never,
      area.id,
    );
  }, []);

  const sortedPhases = area.phases
    .sort((a, b) => a.order - b.order)
    .map(p => p);

  return (
    <div style={{ minWidth: '50%' }}>
      <Card className={classes.card}>
        <Typography variant="h5" component="h2" className={classes.title}>
          {area.name}
        </Typography>
        <div className="flex gap-2 border border-box rounded-md flex-wrap min-h-[50px] max-h-[90px] overflow-auto p-2">
          {sortedPhases.map(phase => (
            <TooltipWrapper
              value={phase.name}
              key={phase.id}
              className="font-medium text-xs"
            >
              <Button
                variant={
                  selectedPhases.length > 0 && selectedPhases.includes(phase)
                    ? 'default'
                    : 'secondary'
                }
                className="h-8 text-xs p-2 overflow-hidden"
                onClick={() => handleSelectPhase(phase.id)}
              >
                <span className="max-w-[100px] truncate text-nowrap">
                  {phase.name}
                </span>
              </Button>
            </TooltipWrapper>
          ))}
        </div>
        <TitleAndButton
          title="Campos"
          fontSize={20}
          handleAdd={() => {
            setFieldModalOpen(true);
          }}
          margin={false}
        />
        <DataTable
          columns={fieldsTableColumns(
            setAreas,
            selectedPhases,
            setSelectedPhases,
            history as never,
            area.id,
            handleEditField,
          )}
          rows={
            selectedPhases?.length &&
            selectedFields &&
            selectedFields?.length >= 0
              ? selectedFields
              : fields
          }
          onRowClick={onFieldRowClick}
        />
        <div style={{ margin: '10px' }} />
        <TitleAndButton
          title="Tarefas"
          fontSize={20}
          handleAdd={() => {
            setTaskModalOpen(true);
          }}
          margin={false}
        />
        <DataTable
          columns={tasksTableColumns(
            setAreas,
            selectedPhases,
            setSelectedPhases,
            tasks,
            history as never,
            area.id,
            handleEditTask,
          )}
          rows={
            selectedPhases?.length && selectedTasks && selectedTasks.length >= 0
              ? selectedTasks
              : tasks
          }
          onRowClick={onTaskRowClick}
        />
      </Card>
      {fieldModalOpen && (
        <Modal
          title="Adicionar Campo"
          titleLeft=""
          open={fieldModalOpen}
          closeModal={handleCloseFieldModal}
        >
          {phase !== undefined ? (
            <>
              <div style={{ margin: '0 20px' }}>
                <Autocomplete
                  options={area.phases}
                  getOptionLabel={option => option.name}
                  onChange={(e, value) => {
                    if (value) setPhase(value.id);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Fase"
                      margin="normal"
                      fullWidth
                    />
                  )}
                  value={area.phases.find(phaseIt => phaseIt.id === phase)}
                />
              </div>
              <CustomField
                closeModal={handleCloseFieldModal}
                areaId={area.id}
                phase_id_prop={phase}
              />
            </>
          ) : (
            <Box p={3}>
              Selecione a fase onde o campo será adicionado:
              <Autocomplete
                options={area.phases}
                getOptionLabel={option => option.name}
                onChange={(e, value) => {
                  setPhase(value!.id);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Fase"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </Box>
          )}
        </Modal>
      )}
      {taskModalOpen && (
        <Modal
          title="Adicionar Tarefa"
          titleLeft=""
          open={taskModalOpen}
          closeModal={handleCloseTaskModal}
        >
          <Task closeModal={handleCloseTaskModal} area={area} />
        </Modal>
      )}
    </div>
  );
};

export default AreaPhaseData;
