/* eslint-disable @typescript-eslint/no-explicit-any */
// hooks/useSignature.ts
import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { COLORS } from 'src/styles/colors';
import api from 'src/services/api';
import { ISignatures } from '../Signature.i';
import { useStyles } from '../styles';

export const useSignature = (
  card_id: string,
  cardField_id: string,
  signature_id: string | null | undefined,
  setSignatureId: ((id: string) => void | undefined) | undefined,
  setSignaturesCollectionStarted: (started: boolean) => void,
) => {
  const today = new Date();
  const minDate = new Date(today.setDate(today.getDate() + 1));
  const [signedDocument, setSignedDocument] = useState<ISignatures>();
  const [expiryDate, setExpiryDate] = useState<string>(suggestNewExpiryDate());

  const classes = useStyles();

  const allSignersCompleted = useCallback(() => {
    return signedDocument?.signers?.every(signer => signer.hasSigned) || false;
  }, [signedDocument?.signers]);

  const fetchSignedDocument = useCallback(async () => {
    if (!signature_id) return;
    try {
      const response = await api.patch(
        `/signatures/${signature_id}/signed-document`,
        {},
      );

      const { fileBase64, filename } = response.data;

      if (fileBase64) {
        const byteCharacters = atob(fileBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i += 1) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename || 'documento_bpm.pdf';
        link.click();

        toast.success('Documento final assinado baixado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      }
    } catch (error) {
      toast.error('Erro ao baixar o documento final assinado.', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  }, [signature_id, allSignersCompleted]);

  function suggestNewExpiryDate() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    return currentDate.toISOString();
  }

  const handleShowSignatures = useCallback(async () => {
    if (!signature_id) {
      try {
        const response = await api.post('/signatures', {
          card_id,
          cardField_id,
        });
        const newSignatureId = response.data.signature_id;
        setSignedDocument(response.data);
        if (setSignatureId && newSignatureId) {
          setSignatureId(newSignatureId);
        }
      } catch (error: any) {
        toast.error(`Ocorreu um erro ao criar a assinatura: ${error.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    } else {
      try {
        const response = await api.get(`/signatures/${signature_id}`);
        const responseData: ISignatures = response.data;
        if (responseData) {
          setSignedDocument(responseData);
          if (responseData.expiryDate) {
            const receivedDateObject = new Date(responseData.expiryDate);
            const currentDate = new Date();
            receivedDateObject.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);
            if (
              receivedDateObject <= currentDate &&
              !responseData.collectionStarted
            ) {
              setExpiryDate(suggestNewExpiryDate());
            } else {
              setExpiryDate(responseData.expiryDate);
            }
          }
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          error.response.data.message !== 'Documento assinado não encontrado.'
        ) {
          toast.error(
            `Falha ao carregar assinaturas. ${
              error?.response?.data?.message || ''
            }`,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            },
          );
        }
      }
    }
  }, [signature_id, card_id, cardField_id, setSignatureId]);

  useEffect(() => {
    handleShowSignatures();
  }, [handleShowSignatures]);

  const handleChangeSignatureProcess = async (
    option?: 'startCollection' | 'cancelCollection' | 'onBlur',
  ) => {
    if (!signedDocument?.id && expiryDate) {
      try {
        const response = await api.put(`/signatures/${signature_id}`, {
          expiryDate,
        });
        if (response.status === 200) {
          setSignedDocument(prevState => ({
            ...prevState!,
            expiryDate,
          }));
          if ((!signature_id || signature_id === '') && response.data.id) {
            if (setSignatureId) {
              setSignatureId(response.data.id);
            }
          }
          toast.success('Data de expiração definida com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        }
      } catch (error: any) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    } else if (
      signedDocument?.id &&
      expiryDate &&
      option === 'startCollection'
    ) {
      try {
        await api.put(`/signatures/${signature_id}`, {
          expiryDate,
        });
        const response = await api.patch(`/signatures/${signature_id}/start`);
        if (response.status === 200) {
          toast.success('Processo de coleta iniciado.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          setSignaturesCollectionStarted(true);
          setSignedDocument(prevState => ({
            ...prevState!,
            collectionStarted: true,
          }));
        }
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message ||
            'Erro ao iniciar processo de assinatura.',
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          },
        );
      }
    } else if (
      signedDocument?.id &&
      signedDocument?.collectionStarted &&
      option === 'cancelCollection'
    ) {
      Swal.fire({
        title: 'Deseja cancelar a coleta de assinaturas?',
        text: 'Essa opção não poderá ser revertida.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: COLORS.GREEN,
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          container: classes.swalAlert,
        },
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            const response = await api.patch(
              `/signatures/${signature_id}/stop`,
            );
            if (response.status === 200) {
              setSignedDocument(prevState => ({
                ...prevState!,
                collectionStarted: false,
              }));
              setSignaturesCollectionStarted(false);
              toast.success('Processo de coleta cancelado.', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              });
            }
          } catch (error: any) {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            });
          }
        }
      });
    } else {
      try {
        await api.put(`/signatures/${signature_id}`, {
          expiryDate,
        });
        if (option !== 'onBlur') {
          toast.success('Dados salvos com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        }
      } catch (error: any) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    }
  };

  const resendEmail = async (signer_id: string) => {
    try {
      await api.patch(
        `/signatures/${signature_id}/signers/${signer_id}/resend-email`,
      );
      toast.success('Email de assinatura reenviado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (error) {
      toast.error('Erro ao reenviar o email de assinatura.', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const resendDocument = async (signer_id: string) => {
    try {
      await api.patch(
        `/signatures/${signature_id}/signers/${signer_id}/resend-concluded-email`,
      );
      toast.success('Email de assinatura reenviado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (error) {
      toast.error('Erro ao reenviar o email de assinatura.', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const anySignerHasSigned = signedDocument?.signers?.some(
    signer => signer.hasSigned,
  );

  return {
    signedDocument,
    expiryDate,
    minDate,
    setExpiryDate,
    handleChangeSignatureProcess,
    handleShowSignatures,
    resendEmail,
    resendDocument,
    fetchSignedDocument,
    allSignersCompleted,
    anySignerHasSigned,
  };
};
