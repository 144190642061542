/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { RichTextEditor } from 'src/components/RichTextEditor/ckeditor';
import TableStyleModal, {
  DocumentTableStyles,
  Table,
} from 'src/components/ModalTableStyles';
import Button from 'src/components/Button';
import { styles } from '../styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

interface DocumentEditorProps {
  cfTables: Table[];
  header: string;
  footer: string;
  template: string;
  templateFieldOptions: any[];
  fetchFieldsCompleted: boolean;
  tableStyles: DocumentTableStyles;
  isTableModalOpen: boolean;
  setHeader: (value: string) => void;
  setFooter: (value: string) => void;
  setTemplate: (value: string) => void;
  setTableStyles: (value: DocumentTableStyles) => void;
  setIsTableModalOpen: (value: boolean) => void;
  setUnsavedChanges: (value: boolean) => void;
}

export const DocumentEditor: React.FC<DocumentEditorProps> = ({
  header,
  setHeader,
  footer,
  setFooter,
  template,
  setTemplate,
  templateFieldOptions,
  fetchFieldsCompleted,
  tableStyles,
  setTableStyles,
  cfTables,
  isTableModalOpen,
  setIsTableModalOpen,
  setUnsavedChanges,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box mt={1} width="100%" className={classes.templateTitle}>
        Template do documento
      </Box>
      <Accordion
        style={{
          width: '550px',
          minWidth: '300px',
          marginBottom: '10px',
        }}
      >
        <AccordionSummary expandIcon={<ArrowDropDown />}>
          Cabeçalho e Rodapé
        </AccordionSummary>
        <AccordionDetails>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Typography
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                marginBottom: '10px',
              }}
            >
              Cabeçalho
            </Typography>
            <RichTextEditor
              propData={header}
              setPropData={(data: string) => {
                setHeader(data);
                setUnsavedChanges(true);
              }}
              toolbarHidden
              suggestionsProps={[]} // headerAndFooterFields
              small
            />
            <Divider
              style={{
                width: '100%',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            />
            <Typography
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                marginBottom: '10px',
              }}
            >
              Rodapé
            </Typography>
            <RichTextEditor
              propData={footer}
              setPropData={(data: string) => {
                setFooter(data);
                setUnsavedChanges(true);
              }}
              toolbarHidden
              suggestionsProps={[]} // headerAndFooterFields
              small
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      {fetchFieldsCompleted ? (
        <>
          <RichTextEditor
            propData={template}
            setPropData={(data: string) => {
              setTemplate(data);
              setUnsavedChanges(true);
            }}
            suggestionsProps={
              templateFieldOptions.length > 0 ? templateFieldOptions : []
            }
            toolbarHidden={false}
            small={false}
          />
          <Button onClick={() => setIsTableModalOpen(!isTableModalOpen)}>
            Estilos de tabela
          </Button>
          <TableStyleModal
            isTableModalOpen={isTableModalOpen}
            setIsTableModalOpen={setIsTableModalOpen}
            tables={cfTables}
            tableStyles={tableStyles}
            setTableStyles={(value: DocumentTableStyles) => {
              setTableStyles(value);
              setUnsavedChanges(true);
            }}
          />
        </>
      ) : (
        <>Loading ...</>
      )}
    </>
  );
};
