/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';
import { getDateInputType } from 'src/utils/customFieldUtils';
import { ICustomField } from '../Search.i';
import { useStyles } from '..';

interface ITimestampInput {
  customField: ICustomField;
  handleSetCustomField: (
    cf: ICustomField,
    value: any,
    isInitial?: boolean,
  ) => void;
}

export const TimestampRangeInput = ({
  customField,
  handleSetCustomField,
}: ITimestampInput) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="start"
      flex-wrap="nowrap"
      flexDirection="row"
    >
      <TextField
        id={customField.name}
        name={customField.name}
        className={classes.fieldFilter}
        value={customField.valueInitial}
        label="De"
        type={getDateInputType(customField.dateType)}
        margin="none"
        fullWidth
        onChange={e => {
          handleSetCustomField(customField, e.target.value, true);
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputProps: {
            inputformat:
              customField.dateType === 'DATE' ? 'dd/mm/yyyy' : 'hh:mm',
            pattern:
              customField.dateType === 'DATE'
                ? '[0-9]{4}-[0-9]{2}-[0-9]{2}'
                : '[0-9]{2}:[0-5]{1}[0-9]{1}',
            min: customField.dateType === 'DATE' ? '1900-05-01' : '00:00',
            max: customField.dateType === 'DATE' ? '2999-12-31' : '23:59',
          },
        }}
      />
      <Box width="5rem" />
      <TextField
        id={customField.name}
        name={customField.name}
        className={classes.fieldFilter}
        value={customField.valueFinal}
        type={getDateInputType(customField.dateType)}
        label="Até"
        margin="none"
        fullWidth
        onChange={e => {
          handleSetCustomField(customField, e.target.value, false);
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputProps: {
            inputformat:
              customField.dateType === 'DATE'
                ? 'dd/mm/yyyy'
                : customField.dateType === 'TIME'
                ? 'hh:mm'
                : 'yyyy-MM-ddThh:mm',
            pattern:
              customField.dateType === 'DATE'
                ? '[0-9]{4}-[0-9]{2}-[0-9]{2}'
                : customField.dateType === 'TIME'
                ? '[0-9]{2}:[0-5]{1}[0-9]{1}'
                : '[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}',
            min:
              customField.dateType === 'DATE'
                ? '1900-05-01'
                : customField.dateType === 'TIME'
                ? '00:00'
                : '1900-05-01T00:00',
            max:
              customField.dateType === 'DATE'
                ? '2999-12-31'
                : customField.dateType === 'TIME'
                ? '23:59'
                : '2999-12-31T23:59',
          },
        }}
      />
    </Box>
  );
};
