/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Box,
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { CustomRenderInputTags } from 'src/components/AutoCompleteCustomComponents/CustomRenderInputTags';
import { styles } from '../styles';
import { IPhase } from '../CustomField.i';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface NumericOptionsProps {
  formik: any;
  areaPhases: IPhase[];
  selectedPhases: IPhase[];
  setSelectedPhases: (value: IPhase[]) => void;
}

export const NumericOptions: React.FC<NumericOptionsProps> = ({
  formik,
  areaPhases,
  selectedPhases,
  setSelectedPhases,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.belongsToTableInput}>
      <FormControlLabel
        control={
          <Checkbox
            name="totalize"
            onChange={formik.handleChange}
            checked={formik.values.totalize}
          />
        }
        label="Totalizar"
      />
      {formik.values.totalize && (
        <Autocomplete
          style={{
            minWidth: '200px',
            maxWidth: '350px',
          }}
          disableClearable
          id="phasesToTotalize"
          getOptionLabel={chosenPhase => chosenPhase.name}
          getOptionSelected={(currentPhase, value) =>
            currentPhase.id === value.id
          }
          options={areaPhases}
          value={selectedPhases}
          onChange={(e, value) => {
            setSelectedPhases(value);
            const formattedValue: string[] = [];

            value.forEach(phase => {
              formattedValue.push(phase.id);
            });
            formik.setFieldValue('phasesToTotalize', formattedValue);
          }}
          multiple
          loadingText="Carregando"
          disableCloseOnSelect
          limitTags={1}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className={classes.checkBoxAutoComplete}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </>
          )}
          renderTags={(tagValue, getTagProps) => {
            return (
              <CustomRenderInputTags
                tagValue={tagValue}
                getTagProps={getTagProps}
                size="medium"
              />
            );
          }}
          renderInput={rest => (
            <TextField
              {...rest}
              id="phasesToTotalize"
              label="Fases"
              margin="normal"
              InputProps={{
                ...rest.InputProps,
                style: {
                  display: 'flex',
                  flexWrap: 'nowrap',
                },
              }}
              error={
                formik.touched.phasesToTotalize &&
                Boolean(formik.errors.phasesToTotalize)
              }
              helperText={
                formik.touched.phasesToTotalize &&
                formik.errors.phasesToTotalize
              }
            />
          )}
        />
      )}
    </Box>
  );
};
