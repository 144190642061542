import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { formatDatetimeLocal } from 'src/utils/dateFormatAndComparisonUtils';
import { getDateInputType } from 'src/utils/customFieldUtils';
import { useCardDataContext } from '../../../../../context/CardDataContext';
import { IDefaultFieldProps } from '../../../Card.i';

export const TimestampField = ({
  customField,
  phaseIndex,
  customFieldIndex,
  cardCustomFieldPhaseId,
  handleFillTableValue,
  tableColumn,
  isInCurrentCardPhase,
}: IDefaultFieldProps): JSX.Element => {
  const { cardCurrentPhase, handleFillCustomField } = useCardDataContext();
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (customField.value && !tableColumn) {
      const formattedValue =
        customField.dateType === 'DATETIME'
          ? formatDatetimeLocal(customField.value)
          : customField.value;
      setInputValue(formattedValue);
    }
    if (tableColumn && tableColumn?.value) {
      const formattedValue =
        customField.dateType === 'DATETIME'
          ? formatDatetimeLocal(tableColumn.value as string)
          : tableColumn.value;
      setInputValue(formattedValue as string);
    }
  }, [customField.value, tableColumn?.tableRowIndex]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  };

  const handleBlur = () => {
    handleFillCustomField(
      phaseIndex,
      customFieldIndex,
      inputValue,
      undefined,
      customField.id,
    );
  };

  const handleBlurTableColumn = (value: string) => {
    if (handleFillTableValue && tableColumn)
      handleFillTableValue(customField.id, tableColumn.tableRowIndex, value);
  };

  return (
    <TextField
      key={
        tableColumn
          ? `${tableColumn.tableId} ${customField.id}`
          : customField.id
      }
      id={customField.name}
      name={customField.name}
      type={getDateInputType(customField.dateType)}
      label={!tableColumn ? customField.name : undefined}
      autoComplete="off"
      margin={!tableColumn ? 'normal' : undefined}
      fullWidth={!tableColumn}
      required={
        customField.isRequired &&
        (cardCurrentPhase?.id === cardCustomFieldPhaseId ||
          isInCurrentCardPhase)
      }
      disabled={customField.isInactive || customField.isDisabled}
      onChange={handleInputChange}
      onBlur={e =>
        tableColumn ? handleBlurTableColumn(e.target.value) : handleBlur()
      }
      value={inputValue}
      InputLabelProps={
        !tableColumn
          ? {
              shrink: true,
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }
          : undefined
      }
      InputProps={{
        disableUnderline: tableColumn ? true : undefined,
        margin: tableColumn ? 'none' : undefined,
        inputProps: {
          style: {
            textAlign: tableColumn ? 'center' : undefined,
          },
          inputformat:
            customField.dateType === 'DATE'
              ? 'dd/mm/yyyy'
              : customField.dateType === 'TIME'
              ? 'hh:mm'
              : 'yyyy-MM-ddThh:mm', // New format for 'datetime-local'
          pattern:
            customField.dateType === 'DATE'
              ? '[0-9]{4}-[0-9]{2}-[0-9]{2}'
              : customField.dateType === 'TIME'
              ? '[0-9]{2}:[0-5]{1}[0-9]{1}'
              : '[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}', // Pattern for 'datetime-local'
          min:
            customField.dateType === 'DATE'
              ? '1900-05-01'
              : customField.dateType === 'TIME'
              ? '00:00'
              : '1900-05-01T00:00', // Min for 'datetime-local'
          max:
            customField.dateType === 'DATE'
              ? '2999-12-31'
              : customField.dateType === 'TIME'
              ? '23:59'
              : '2999-12-31T23:59', // Max for 'datetime-local'
        },
      }}
    />
  );
};
