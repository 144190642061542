/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button as ButtonMaterialUI,
  Checkbox,
  Container,
  createStyles,
  FormControlLabel,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import api from '../../../../services/api';
import Button from '../../../../components/Button';
import {
  IAccountFieldParams,
  IAccountFieldProps,
  IAccountField,
  typeAccountFields,
} from '../AccountConfiguration.i';
import { styles } from '../../styles';
import {
  dateTypes,
  formatSlug,
  generateSlug,
} from '../../../../utils/customFieldUtils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const AccountFieldConfiguration: React.FC<IAccountFieldProps> = ({
  closeModal = () => null,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const params = useParams<IAccountFieldParams>();
  const [accountField, setAccountField] = useState<IAccountField>();
  const [selectedType, setSelectedType] = useState<{
    id: string;
    label: string;
  }>({
    id: 'STRING',
    label: 'Alfanumérico',
  });
  const [predefinedOptions, setPredefinedOptions] = useState<string[]>([]);
  const [newPredefinedOption, setNewPredefinedOption] = useState('');

  const { accountField_id } = params;

  const goBack = () => {
    if (!accountField_id) {
      closeModal(true);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    if (accountField_id)
      api.get(`/accountFields/${accountField_id}`).then(response => {
        setAccountField(response.data);
        const type = typeAccountFields.find(
          type => type.id === response.data.type,
        );
        if (type !== undefined) setSelectedType(type);
        const predefinedValues = response.data?.predefinedValues || [];
        if (predefinedValues && predefinedValues.length > 0)
          setPredefinedOptions(predefinedValues);
      });
  }, [accountField_id, typeAccountFields]);

  type OmitMultiple<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

  const initialValues: OmitMultiple<IAccountField, 'id' | 'order'> = {
    name: accountField?.name ?? '',
    type: accountField?.type ?? 'STRING',
    dateType:
      accountField?.type === 'TIMESTAMP' && accountField?.dateType
        ? accountField?.dateType
        : 'DATE',
    isInactive: accountField?.isInactive ?? false,
    isRequired: accountField?.isRequired ?? false,
    isBigString: accountField?.isBigString ?? false,
    predefinedValues: accountField?.predefinedValues ?? [],
    slug: accountField?.slug ?? '',
  };

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required('Nome é obrigatório.')
      .max(100, 'O nome ultrapassou o limite de caracteres permitido.'),
    type: Yup.string().required('Tipo é obrigatório.'),
    dateType:
      selectedType && selectedType.id === 'TIMESTAMP'
        ? Yup.string().required('Tipo de data é obrigatório.')
        : Yup.string().optional(),
    isInactive: Yup.boolean().required('Estado inativo é obrigatório.'),
    isRequired: Yup.boolean().required('Campo obrigatório é obrigatório.'),
    isBigString: Yup.boolean().required('String grande é obrigatório.'),
    predefinedValues:
      selectedType &&
      selectedType.id === 'PREDEFINED_STRINGS' &&
      !accountField?.predefinedValues
        ? Yup.array().required('É necessário informar ao menos um valor').min(1)
        : Yup.array().optional(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        if (accountField_id) {
          await api
            .put(`/accountFields/${accountField_id}`, {
              name: values.name,
              // type: values.type,
              // dateType: values.dateType,
              isInactive: values.isInactive,
              isRequired: values.isRequired,
              isBigString: values.isBigString,
              slug: values.slug,
              predefinedValues:
                values.type !== 'PREDEFINED_STRINGS'
                  ? undefined
                  : values.predefinedValues,
            })
            .then(response => {
              const { message, status } = response?.data;

              if (message && status && status === 'warning') {
                toast.warning(message, {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                  autoClose: 5000,
                });
              } else {
                toast.success('Conta Editada com sucesso.', {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                });
              }
            })
            .catch(e => {
              toast.error(
                `Falha ao editar campo de conta. ${e.response.data.message}`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                },
              );
            });
        } else {
          await api
            .post('/accountFields', {
              name: values.name,
              type: values.type,
              dateType:
                values.type !== 'TIMESTAMP' ? undefined : values.dateType,
              isInactive: values.isInactive,
              isRequired: values.isRequired,
              isBigString: values.isBigString,
              slug: values.slug,
              predefinedValues:
                values.type !== 'PREDEFINED_STRINGS'
                  ? undefined
                  : values.predefinedValues,
            })
            .then(response => {
              const { message, status } = response?.data;

              if (message && status && status === 'warning') {
                toast.warning(message, {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                  autoClose: 5000,
                });
              } else {
                toast.success('Campo de conta cadastrado com sucesso.', {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                });
                setTimeout(() => {
                  history.go(0);
                }, 3000);
              }
            })
            .catch(e => {
              toast.error(
                `Falha ao cadastrar campo de conta. ${e.response.data.message}`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                },
              );
            });
        }
      } catch (error: any) {
        setSubmitting(false);

        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      }
    },
  });

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: accountField?.id ? 35 : 15,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        {accountField_id ? (
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Editar Campo de Conta
          </Typography>
        ) : (
          <div />
        )}

        <Box style={{ marginTop: '25px' }}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <TextField
              style={{
                width: accountField_id ? '80%' : undefined,
                maxWidth: '550px',
              }}
              id="name"
              label="Nome"
              name="name"
              type="text"
              margin="dense"
              autoComplete="off"
              autoFocus
              fullWidth
              onBlur={event => {
                const formattedValue = generateSlug(event.target.value);
                if (event.target.value && !formik.values.slug)
                  formik.setFieldValue('slug', formattedValue);
              }}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              style={{
                width: accountField_id ? '80%' : undefined,
                maxWidth: '550px',
              }}
              id="slug"
              label="Apelido"
              name="slug"
              margin="normal"
              fullWidth
              onChange={event => {
                const formattedValue = formatSlug(event.target.value);
                formik.setFieldValue('slug', formattedValue);
              }}
              onKeyDown={event => {
                if (event.key === ' ' || !/[a-zA-Z0-9_.-]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={formik.values.slug}
              error={formik.touched.slug && Boolean(formik.errors.slug)}
              helperText={formik.touched.slug && formik.errors.slug}
            />
            <Autocomplete
              style={{
                width: accountField_id ? '80%' : undefined,
                maxWidth: '550px',
              }}
              id="type"
              disableClearable
              getOptionLabel={typeField => typeField.label}
              getOptionSelected={(typeField, value) =>
                typeField.id === value.id
              }
              options={typeAccountFields}
              disabled={!!accountField_id}
              fullWidth
              value={selectedType}
              onChange={(e, value) => {
                setSelectedType(
                  value || {
                    id: 'STRING',
                    label: 'Alfanumérico',
                  },
                );
                formik.setFieldValue(
                  'type',
                  value !== null ? value.id : initialValues.type,
                );
              }}
              loadingText="Carregando"
              renderInput={rest => (
                <TextField
                  {...rest}
                  id="typeField"
                  name="type"
                  label="Tipos de Campo"
                  margin="normal"
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  helperText={formik.touched.type && formik.errors.type}
                  InputProps={{
                    ...rest.InputProps,
                  }}
                />
              )}
            />

            {selectedType && (
              <>
                {selectedType.id === 'TIMESTAMP' && (
                  <Autocomplete
                    id="dateType"
                    style={{
                      width: accountField_id ? '80%' : undefined,
                      maxWidth: '550px',
                    }}
                    disableClearable
                    getOptionLabel={opt => opt.label}
                    getOptionSelected={(opt, value) => opt.id === value.id}
                    options={dateTypes}
                    disabled={!!accountField_id}
                    fullWidth
                    value={dateTypes.find(
                      dt => dt.id === formik.values.dateType,
                    )}
                    onChange={(e, value) => {
                      formik.setFieldValue(
                        'dateType',
                        value !== null ? value.id : initialValues.dateType,
                      );
                    }}
                    loadingText="Carregando"
                    renderInput={rest => (
                      <TextField
                        {...rest}
                        id="dateType"
                        name="dateType"
                        label="Tipos de tempo"
                        margin="normal"
                        error={
                          formik.touched.dateType &&
                          Boolean(formik.errors.dateType)
                        }
                        helperText={
                          formik.touched.dateType && formik.errors.dateType
                        }
                        InputProps={{
                          ...rest.InputProps,
                        }}
                      />
                    )}
                  />
                )}
                {selectedType.id === 'PREDEFINED_STRINGS' && (
                  <Autocomplete
                    style={{
                      width: accountField_id ? '80%' : undefined,
                      maxWidth: '550px',
                    }}
                    id="predifined_strings"
                    multiple
                    freeSolo
                    limitTags={6}
                    options={predefinedOptions}
                    getOptionSelected={(predefinedOptions, value) =>
                      predefinedOptions === value
                    }
                    fullWidth
                    onChange={(e, value) => {
                      setPredefinedOptions(value || []);
                      setNewPredefinedOption('');
                      formik.setFieldValue(
                        'predefinedValues',
                        value !== null ? value : [],
                      );
                    }}
                    value={predefinedOptions || []}
                    inputValue={newPredefinedOption}
                    onInputChange={(e, value, reason) => {
                      if (reason === 'input') {
                        setNewPredefinedOption(value);
                      }
                    }}
                    loadingText="Carregando"
                    renderInput={rest => (
                      <TextField
                        {...rest}
                        id="predifined_strings"
                        label="Valores Predefinidos"
                        margin="normal"
                        type="text"
                        placeholder="Inserir um novo valor..."
                        error={
                          formik.touched.predefinedValues &&
                          Boolean(formik.errors.predefinedValues)
                        }
                        helperText={
                          formik.touched.predefinedValues &&
                          formik.errors.predefinedValues
                        }
                        InputProps={{
                          ...rest.InputProps,
                        }}
                      />
                    )}
                  />
                )}
              </>
            )}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {selectedType && selectedType.id === 'STRING' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isBigString"
                      onChange={formik.handleChange}
                      checked={formik.values.isBigString}
                    />
                  }
                  label="Múltiplas linhas"
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    name="isRequired"
                    onChange={formik.handleChange}
                    checked={formik.values.isRequired}
                  />
                }
                label="Campo obrigatório"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="isInactive"
                    onChange={formik.handleChange}
                    checked={formik.values.isInactive}
                  />
                }
                label="Inativar"
              />
            </div>

            <ButtonMaterialUI
              className={classes.buttonSubmit}
              disabled={formik.isSubmitting}
              size="large"
              variant="contained"
              color="inherit"
              onClick={goBack}
            >
              Voltar
            </ButtonMaterialUI>
            <Button
              className={classes.buttonSubmit}
              disabled={formik.isSubmitting}
              type="submit"
            >
              Salvar
            </Button>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default AccountFieldConfiguration;
