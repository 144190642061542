import { FieldTypes } from 'src/utils/fieldTypes.constants';
import { comparisonOperator } from 'src/utils/conditionOperators';
import { toast } from 'react-toastify';
import { dateTypes } from 'src/utils/customFieldUtils';
import {
  IFillRule,
  ICardData,
  TFillRuleCondition,
  TConditionOperator,
  IAccount,
} from '../../Card.i';
import {
  findCustomFieldById,
  findTaskById,
  mapPartialToTimestamp,
  mapTimestampToMilliseconds,
} from '../cardUtils';

/**
 * Evaluate the whole fill rule condition expression
 * @param {IFillRule} rule - the original expression string
 * @param {ICardData[]} cardFields - array of card fields and values
 * @param {IAccount} accountFields - object with account fields and values
 * @param {ICustomField | undefined} currentCardPhaseId - the current phase id
 * @returns 'boolean - if the conditions are met or not'
 */
export function evaluateConditionsExpression(
  rule: IFillRule,
  cardFields: ICardData[],
  accountFields: IAccount,
  currentCardPhaseId: string,
): boolean {
  const { action, conditions, expression } = rule;
  let executeRule = false;
  let currentConditionResult = false;
  let lastOperator: 'AND' | 'OR' = 'OR';

  // return true if there is no condition expression
  if (action && (!expression || expression.length === 0)) {
    return true;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const condition of expression) {
    if (typeof condition === 'string') {
      lastOperator = condition as 'AND' | 'OR';
      if (condition === 'AND' && currentConditionResult === false) {
        executeRule = false;
        break;
      }
    } else {
      currentConditionResult = evaluateArrayOfConditions(
        conditions,
        condition as string[],
        cardFields,
        accountFields,
        currentCardPhaseId,
      );
    }

    if (lastOperator === 'AND') {
      executeRule = executeRule && currentConditionResult;
    } else {
      executeRule = executeRule || currentConditionResult;
    }
  }

  return executeRule;
}

/**
 * Compare if the array of phase ids contains or not the current phase id
 * @param {string[]} phaseIds - array of phase ids
 * @param {string} operator - array of card fields and values
 * @param {string} currentCardPhaseId - the current phase id
 * @returns 'boolean - if the condition are met or not'
 */
const performPhaseComparison = (
  phaseIds: string[],
  operator: string,
  currentCardPhaseId: string,
) => {
  switch (operator) {
    case '=':
      return phaseIds.includes(currentCardPhaseId);
    case '!=':
      return !phaseIds.includes(currentCardPhaseId);
    default:
      return false;
  }
};

/**
 * Evaluate a group of fill rule condition
 * @param {TFillRuleCondition[]} ruleConditions - array of rule conditions
 * @param {string[]} conditionArray - array of card fields and values
 * @param {ICardData[]} cardFields - array of cardFields
 * @param {IAccount} accountFields - object with account fields and values
 * @param {string} currentCardPhaseId - the current phase id
 * @returns 'boolean - if the conditions are met or not'
 */
export function evaluateArrayOfConditions(
  ruleConditions: TFillRuleCondition[],
  conditionArray: string[],
  cardFields: ICardData[],
  accountFields: IAccount,
  currentCardPhaseId: string,
): boolean {
  const cardCustomFields = cardFields;
  let lastOperator: 'AND' | 'OR' = 'OR';
  let currentConditionResult = false;
  let result = false;

  conditionArray.forEach(condition => {
    switch (condition) {
      case 'AND':
        if (!result) currentConditionResult = false;
        lastOperator = 'AND';
        break;
      case 'OR':
        lastOperator = 'OR';
        break;
      default: {
        const fullCondition = ruleConditions.find(c => condition === c.id);
        if (!fullCondition) {
          currentConditionResult = false;
          toast.error('Condição da regra de preenchimetno não encontrada.', {
            autoClose: 5000,
            theme: 'colored',
            closeOnClick: true,
          });
          break;
        }

        switch (fullCondition.type) {
          case 'customField': {
            if (!fullCondition.value) {
              currentConditionResult = false;
              break;
            }

            const cardCustomField = findCustomFieldById(
              cardCustomFields,
              fullCondition.customField.id,
            );

            if (!cardCustomField) {
              if (fullCondition.operator === '!∃') {
                currentConditionResult = true;
                break;
              }

              currentConditionResult = false;
              break;
            }

            let fillValue: string;
            if (
              fullCondition.customField.type === 'PREDEFINED_STRINGS' ||
              fullCondition.customField.type === 'FILE'
            ) {
              if (!cardCustomField.valueJSON) {
                currentConditionResult = false;
                break;
              }
              fillValue = (
                cardCustomField.valueJSON as unknown as string[]
              ).join(',');
            } else {
              fillValue = cardCustomField.value;
            }

            currentConditionResult = evaluateComparison(
              fullCondition.customField.type,
              fullCondition.operator,
              fillValue,
              fullCondition.value,
              fullCondition.customField.dateType,
            );
            break;
          }
          case 'accountField': {
            if (!fullCondition.value) {
              currentConditionResult = false;
              break;
            }

            const accountCustomField = accountFields.fieldValues.find(
              item => item.field.id === fullCondition.accountField_id,
            );

            if (!accountCustomField) {
              if (fullCondition.operator === '!∃') {
                currentConditionResult = true;
                break;
              }
              currentConditionResult = false;
              break;
            }

            let fillValue: string;
            if (
              accountCustomField.field.type === 'PREDEFINED_STRINGS' ||
              accountCustomField.field.type === 'FILE'
            ) {
              if (!accountCustomField.valueJSON) {
                currentConditionResult = false;
                break;
              }
              fillValue = (
                accountCustomField.valueJSON as unknown as string[]
              ).join(',');
            } else {
              fillValue = accountCustomField.value as string;
            }

            currentConditionResult = evaluateComparison(
              accountCustomField.field.type,
              fullCondition.operator,
              fillValue,
              fullCondition.value,
              accountCustomField.field.dateType,
            );
            break;
          }
          case 'accountFieldFixed': {
            if (!fullCondition.value) {
              currentConditionResult = false;
              break;
            }
            const { accountFieldName, value, operator } = fullCondition;

            const fieldValue = accountFieldName
              ? accountFields[accountFieldName] || ''
              : '';

            if (fieldValue) {
              currentConditionResult = comparisonOperator[operator](
                fieldValue,
                value,
              );
            } else {
              currentConditionResult = false;
            }
            break;
          }
          case 'phase': {
            const phaseIds = fullCondition.phase_ids || [];
            const { operator } = fullCondition;
            const comparisonResult = performPhaseComparison(
              phaseIds,
              operator,
              currentCardPhaseId,
            );
            currentConditionResult = comparisonResult;
            break;
          }
          case 'task': {
            const { taskDone, task } = fullCondition;
            const taskFound = findTaskById(cardFields, task?.id || '');
            if (taskFound && taskDone === taskFound.done) {
              currentConditionResult = true;
            } else {
              currentConditionResult = false;
            }
            break;
          }
          default:
            currentConditionResult = false;
            toast.error(
              `Tipo de condição "${fullCondition.type}" da regra de preenchimento não existe.`,
              {
                autoClose: 5000,
                theme: 'colored',
              },
            );
            break;
        }

        break;
      }
    }

    if (lastOperator === 'AND') {
      result = result && currentConditionResult;
    } else {
      result = result || currentConditionResult;
    }

    return condition;
  });

  return result;
}

/**
 * Compare a single condition value against its card value
 * @param {string} type - the condition custom field type
 * @param {TConditionOperator} operator - operator string
 * @param {string} cardFieldValue - the card value of this field
 * @param {string} conditionValue - the condition value
 * @param {string} dateType - the custom field dateType if it's a timestamp
 * @returns 'boolean - if the condition are met or not'
 */
export function evaluateComparison(
  type: string,
  operator: TConditionOperator,
  cardFieldValue: string,
  conditionValue: string,
  dateType: string,
): boolean {
  let op1;
  let op2;
  const timePattern = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  const datetimeLocalPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;

  switch (type) {
    case FieldTypes.BOOL:
      if (cardFieldValue !== 'true' && cardFieldValue !== 'false') {
        op1 = null;
      } else {
        op1 = cardFieldValue === 'true';
      }
      op2 = conditionValue === 'true';
      break;
    case FieldTypes.NUMERIC:
      op1 = Number(cardFieldValue);
      op2 = Number(conditionValue);
      break;
    case FieldTypes.STRING:
      op1 = cardFieldValue.trim().toUpperCase();
      op2 = conditionValue.trim().toUpperCase();
      break;
    case FieldTypes.PREDEFINED_STRINGS: {
      const cardValues = cardFieldValue.trim().toUpperCase().split(',');
      const requiredValues = conditionValue.trim().toUpperCase().split(',');
      const thereIsEqualValues = requiredValues.every(s =>
        cardValues.includes(s),
      );
      const thereIsDifferentValues = requiredValues.every(
        s => !cardValues.includes(s),
      );
      const result =
        operator === '=' ? thereIsEqualValues : thereIsDifferentValues;
      return result;
    }
    case FieldTypes.TIMESTAMP:
      if (dateTypes.find(d => d.id === dateType) === undefined) return false;

      if (
        cardFieldValue === '' ||
        (dateType === 'DATE' && !datePattern.test(cardFieldValue)) ||
        (dateType === 'TIME' && !timePattern.test(cardFieldValue)) ||
        (dateType === 'DATETIME' && !datetimeLocalPattern.test(cardFieldValue))
      ) {
        return false;
      }

      op1 = mapTimestampToMilliseconds(
        mapPartialToTimestamp(cardFieldValue, dateType),
        dateType,
      );
      op2 = mapTimestampToMilliseconds(
        mapPartialToTimestamp(conditionValue, dateType),
        dateType,
      );
      break;
    case FieldTypes.TABLE:
    case FieldTypes.INTEGRATED:
    case FieldTypes.FILE:
      op1 = cardFieldValue;
      break;
    default:
      return false;
  }

  return comparisonOperator[operator](op1, op2);
}
