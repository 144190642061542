/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Box,
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { styles } from '../styles';
import { IBpmField, ICustomField } from '../CustomField.i';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

interface BelongsToTableProps {
  formik: any;
  loading: boolean;
  belongsToTable: boolean;
  handleToggleBelongsToTable: () => void;
  customField: ICustomField;
  customFields: IBpmField[];
  setSelectedTable: (value: any) => void;
}

export const BelongsToTable: React.FC<BelongsToTableProps> = ({
  formik,
  loading,
  belongsToTable,
  handleToggleBelongsToTable,
  customField,
  customFields,
  setSelectedTable,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.belongsToTableInput}>
      {!loading && (
        <FormControlLabel
          control={
            <Checkbox
              name="tabela"
              checked={belongsToTable}
              onChange={handleToggleBelongsToTable}
            />
          }
          label="Pertence a uma tabela"
        />
      )}
      {belongsToTable && (
        <Autocomplete
          style={{
            minWidth: '200px',
            maxWidth: '550px',
          }}
          disableClearable
          id="customFieldTable"
          getOptionLabel={chosenTable => chosenTable.name}
          getOptionSelected={(currentTable, value) =>
            currentTable.id === value.id
          }
          options={customFields}
          value={
            formik.values.customFieldTable
              ? formik.values.customFieldTable
              : customField && customField.table
              ? customField.table
              : null
          }
          onChange={(e, value) => {
            formik.setFieldValue('customFieldTable', value);
            setSelectedTable(value);
          }}
          loadingText="Carregando"
          renderInput={rest => (
            <TextField
              {...rest}
              id="customFieldTable_id"
              label="Tabelas Disponíveis"
              margin="dense"
              error={
                formik.touched.customFieldTable &&
                Boolean(formik.errors.customFieldTable)
              }
              helperText={
                formik.touched.customFieldTable &&
                formik.errors.customFieldTable
              }
              InputProps={{
                ...rest.InputProps,
              }}
            />
          )}
        />
      )}
    </Box>
  );
};
