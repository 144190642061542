/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Container } from '@material-ui/core';
import {
  Box,
  Button as ButtonMaterialUI,
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import { ICustomFieldProps, ICustomFieldParams } from './CustomField.i';
import { styles } from './styles';
import { useCustomField } from './hooks/useCustomField';
import { ConfirmEditDialog } from './components/ConfirmEditDialog';
import { DefaultFields } from './components/DefaultFields';
import { TimestampOptions } from './components/TimestampOptions';
import { IntegrationRuleOptions } from './components/IntegrationRuleOptions';
import { PredefinedOptions } from './components/PredefinedOptions';
import { FieldFillRules } from './components/FieldFillRules';
import { NumericOptions } from './components/NumericOptions';
import { BelongsToTable } from './components/BelongsToTable';
import { DocumentEditor } from './components/DocumentEditor';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const CustomField: React.FC<ICustomFieldProps> = ({
  closeModal = () => null,
  areaId,
  phase_id_prop,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<ICustomFieldParams>();
  const { customField_id } = params;
  let { phase_id } = params;
  if (phase_id_prop) {
    phase_id = phase_id_prop;
  }

  const goBack = () => {
    if (!customField_id) {
      closeModal();
    } else {
      history.goBack();
    }
  };

  const {
    formik,
    typeCustomFieldSelected,
    setTypeCustomFieldSelected,
    selectedTable,
    setSelectedTable,
    belongsToTable,
    handleToggleBelongsToTable,
    setUnsavedChanges,
    confirmationModalOpen,
    setConfirmationModalOpen,
    isFillRulesOpen,
    setIsFillRuleOpens,
    fetchFieldsCompleted,
    template,
    setTemplate,
    header,
    setHeader,
    footer,
    setFooter,
    tableStyles,
    setTableStyles,
    isTableModalOpen,
    setIsTableModalOpen,
    cfTables,
    integrationRules,
    loadingRules,
    setFilters,
    filters,
    selectedPhases,
    setSelectedPhases,
    areaPhases,
    fetchCustomFieldFillRules,
    handleSubmitWrapper,
    nextLocation,
    setNewPredefinedOption,
    newPredefinedOption,
    predefinedOptions,
    setPredefinedOptions,
    loading,
    phase,
    initialValues,
    customField,
    templateFieldOptions,
    customFields,
  } = useCustomField({ customField_id, areaId, phase_id, closeModal });

  return (
    <Container
      maxWidth={false}
      style={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
        display: 'flex',
      }}
    >
      {/* Modal de confirmação de mudanças não salvas */}
      <ConfirmEditDialog
        confirmationModalOpen={confirmationModalOpen}
        setUnsavedChanges={setUnsavedChanges}
        setConfirmationModalOpen={setConfirmationModalOpen}
        formik={formik}
        loading={loading}
        nextLocation={nextLocation}
      />
      <Box className={classes.content}>
        {customField_id ? (
          <Box>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Editar Campo Personalizado
            </Typography>
          </Box>
        ) : (
          <div />
        )}
        <Box>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" alignItems="start">
              <DefaultFields
                customField_id={customField_id}
                phase={phase}
                formik={formik}
                setTypeCustomFieldSelected={setTypeCustomFieldSelected}
                initialValues={initialValues}
              />

              {typeCustomFieldSelected === 'TIMESTAMP' && (
                <TimestampOptions
                  customField_id={customField_id}
                  formik={formik}
                  initialValues={initialValues}
                />
              )}

              {typeCustomFieldSelected === 'INTEGRATED' && (
                <IntegrationRuleOptions
                  customField_id={customField_id}
                  formik={formik}
                  initialValues={initialValues}
                  integrationRules={integrationRules}
                  loadingRules={loadingRules}
                  setFilters={setFilters}
                  filters={filters}
                  selectedTable={selectedTable}
                  phase_id={phase_id}
                  customField={customField}
                />
              )}

              {typeCustomFieldSelected === 'PREDEFINED_STRINGS' && (
                <PredefinedOptions
                  customField_id={customField_id}
                  formik={formik}
                  predefinedOptions={predefinedOptions}
                  setPredefinedOptions={setPredefinedOptions}
                  newPredefinedOption={newPredefinedOption}
                  setNewPredefinedOption={setNewPredefinedOption}
                  initialValues={initialValues}
                />
              )}

              {customField?.id && (
                <FieldFillRules
                  fetchCustomFieldFillRules={fetchCustomFieldFillRules}
                  handleSubmitWrapper={handleSubmitWrapper}
                  isFillRulesOpen={isFillRulesOpen}
                  setIsFillRuleOpens={setIsFillRuleOpens}
                  customField={customField}
                  areaPhases={areaPhases}
                />
              )}

              {typeCustomFieldSelected === 'DOCUMENT' && (
                <DocumentEditor
                  header={header}
                  setHeader={setHeader}
                  footer={footer}
                  setFooter={setFooter}
                  template={template}
                  setTemplate={setTemplate}
                  tableStyles={tableStyles}
                  setTableStyles={setTableStyles}
                  isTableModalOpen={isTableModalOpen}
                  setIsTableModalOpen={setIsTableModalOpen}
                  cfTables={cfTables}
                  setUnsavedChanges={setUnsavedChanges}
                  fetchFieldsCompleted={fetchFieldsCompleted}
                  templateFieldOptions={templateFieldOptions}
                />
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    name="isRequired"
                    onChange={formik.handleChange}
                    checked={formik.values.isRequired}
                  />
                }
                label="Campo obrigatório"
              />

              {typeCustomFieldSelected !== 'TABLE' &&
                typeCustomFieldSelected !== 'FILE' &&
                typeCustomFieldSelected !== 'DOCUMENT' && (
                  <BelongsToTable
                    formik={formik}
                    loading={loading}
                    belongsToTable={belongsToTable}
                    handleToggleBelongsToTable={handleToggleBelongsToTable}
                    customField={customField}
                    customFields={customFields}
                    setSelectedTable={setSelectedTable}
                  />
                )}

              {typeCustomFieldSelected === 'NUMERIC' && (
                <NumericOptions
                  formik={formik}
                  areaPhases={areaPhases}
                  selectedPhases={selectedPhases}
                  setSelectedPhases={setSelectedPhases}
                />
              )}

              {typeCustomFieldSelected === 'DOCUMENT' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isEditable"
                      onChange={formik.handleChange}
                      checked={formik.values.isEditable}
                    />
                  }
                  label="Permite edição do template"
                />
              )}

              {(typeCustomFieldSelected === 'DOCUMENT' ||
                typeCustomFieldSelected === 'FILE') && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isEncrypted"
                        onChange={formik.handleChange}
                        checked={formik.values.isEncrypted}
                        disabled
                      />
                    }
                    label="Criptografar arquivo"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isRequiredToSign"
                        onChange={formik.handleChange}
                        checked={formik.values.isRequiredToSign}
                        disabled={formik.values.fileAllowsMultiple}
                      />
                    }
                    label="Exige assinatura eletrônica"
                  />
                </>
              )}

              {typeCustomFieldSelected === 'FILE' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="fileAllowsMultiple"
                      onChange={formik.handleChange}
                      checked={formik.values.fileAllowsMultiple}
                      disabled={formik.values.isRequiredToSign}
                    />
                  }
                  label="Permite múltiplos arquivos"
                />
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    name="isInactive"
                    onChange={formik.handleChange}
                    checked={formik.values.isInactive}
                  />
                }
                label="Inativar"
              />

              {typeCustomFieldSelected === 'STRING' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isBigString"
                      onChange={formik.handleChange}
                      checked={formik.values.isBigString}
                    />
                  }
                  label="Múltiplas linhas"
                />
              )}

              <Box mb={15}>
                <ButtonMaterialUI
                  className={classes.buttonSubmit}
                  disabled={formik.isSubmitting}
                  size="large"
                  variant="contained"
                  color="inherit"
                  onClick={goBack}
                >
                  Voltar
                </ButtonMaterialUI>
                <Button
                  className={classes.buttonSubmit}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    setUnsavedChanges(false);
                  }}
                >
                  Salvar
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
};
