/* eslint-disable camelcase */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Typography, Box, Container } from '@material-ui/core';
import { CopyrightBrasao } from 'src/components/CopyrightBrasao';
import logo from '../../assets/logo-brasao-bpm.svg';

export const SignatureCompleted: React.FC = () => {
  return (
    <Container
      maxWidth={false}
      style={{
        backgroundColor: '#eaeeed',
        height: '100vh',
        width: '100%',
        padding: 0,
        margin: 0,
      }}
    >
      <Box
        style={{
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '400px',
        }}
        component="main"
      >
        <img
          src={logo}
          alt="Logo da Empresa Brasão Sistemas"
          style={{
            width: '250px',
            marginTop: '50px',
          }}
        />
        <Typography variant="h5" style={{ marginTop: '15px' }}>
          Coleta concluída
        </Typography>
        <Box mt={5} mb={5} fontWeight={500}>
          <Typography variant="body1" align="justify">
            Sua assinatura foi coletada com sucesso. Assim que o processo for
            concluído por todos os envolvidos, um novo e-mail será enviado com
            novas informações.
          </Typography>
        </Box>
      </Box>
      <CopyrightBrasao />
    </Container>
  );
};
