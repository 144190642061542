/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { styles } from '../styles';
import { IntegrationFilters } from './IntegrationFilters';
import { ICustomField, IFilter, IFormData } from '../CustomField.i';

interface IntegrationRuleOptionsProps {
  loadingRules: boolean;
  integrationRules: any[];
  customField_id?: string;
  selectedTable: string;
  phase_id: string;
  setFilters: (value: IFilter[]) => void;
  filters: IFilter[];
  formik: any;
  initialValues: IFormData;
  customField: ICustomField;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const IntegrationRuleOptions: React.FC<IntegrationRuleOptionsProps> = ({
  loadingRules,
  integrationRules,
  customField_id,
  selectedTable,
  phase_id,
  setFilters,
  filters,
  formik,
  initialValues,
  customField,
}) => {
  const classes = useStyles();

  return (
    <>
      {loadingRules ? (
        <Typography> Loading ... </Typography>
      ) : (
        <>
          <Autocomplete
            style={{
              width: customField_id ? '80%' : undefined,
              maxWidth: '550px',
            }}
            id="integrationRule"
            disableClearable
            getOptionLabel={rule => rule.description}
            getOptionSelected={(rule, value) => rule.id === value.id}
            options={integrationRules}
            disabled={customField && !!customField.integrationRule}
            defaultValue={integrationRules[0]}
            value={formik.values.integrationRule}
            fullWidth
            loadingText="Carregando"
            onChange={(e, value) => {
              if (!customField_id) {
                const arr = Object.keys(value.integrationType.filterFields);
                const mapFilters = arr.map(element => {
                  return {
                    filterCode: element,
                    isActive: false,
                  };
                });

                setFilters(mapFilters);
              }

              formik.setFieldValue(
                'integrationRule',
                value !== null ? value : initialValues.integrationRule,
              );
            }}
            renderInput={rest => (
              <TextField
                {...rest}
                id="integrationRule_text"
                label="Regra de integração"
                margin="normal"
                error={
                  formik.touched.integrationRule &&
                  Boolean(formik.errors.integrationRule)
                }
                helperText={
                  formik.touched.integrationRule &&
                  formik.errors.integrationRule
                }
                InputProps={{
                  ...rest.InputProps,
                }}
              />
            )}
          />
          <Box className={classes.integrationFilters}>
            {!customField_id ? (
              <Typography
                className={classes.disabledIntegrationFilters}
                style={{ color: '#cbcbcb' }}
              >
                Filtros disponíveis na tela editar
              </Typography>
            ) : (
              <IntegrationFilters
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
                selectedTable={selectedTable}
                phase={phase_id}
                filters={filters}
                id={customField_id}
                setFilters={setFilters}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};
